import {A} from "@solidjs/router";
import {Component, createResource, createSignal, For} from "solid-js";
import BottomNavigation from "../BottomNavigation";
import {Book, dao} from "../../data";
import {BookImage} from "../BookImage";

const Favorites: Component = () => {
    const [isScrolled, setIsScrolled] = createSignal(false);
    const [books] = createResource(dao.fetchBooks.bind(dao));

    let mainRef: HTMLDivElement;

    const onScroll = (event: Event) => {
        setIsScrolled(mainRef.scrollTop > 0);
    }
    return (
        <div class="flex flex-col h-full bg-cantico-50 overflow-hidden">

            {/*Top bar*/}
            <div class={`${isScrolled() ? "shadow-lg" : "shadow-none"} transition-shadow duration-300 z-10 shrink-0 flex items-center w-full py-2 px-4 safe-top`}>
                <div class="relative rounded-lg shadow-md bg-white h-10 w-10 shrink-0 flex items-center justify-center overflow-hidden">
                    <img src="cantico.svg"/>
                </div>
                <div class="ml-2 relative rounded-lg shadow-md w-full grow">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <svg class="fill-gray-400 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>
                    </div>
                    <input type="text" class="focus:outline-0 focus:ring-0 block w-full rounded-lg border-0 py-2 pl-10 bg-white placeholder:text-gray-300" placeholder="Suchen..."/>
                </div>
                {/*<button class="ml-2 relative rounded-lg shadow-md bg-white h-10 w-10 shrink-0 flex items-center justify-center">*/}
                {/*    <svg class="h-5 fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 416c0 17.7 14.3 32 32 32l54.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 448c17.7 0 32-14.3 32-32s-14.3-32-32-32l-246.7 0c-12.3-28.3-40.5-48-73.3-48s-61 19.7-73.3 48L32 384c-17.7 0-32 14.3-32 32zm128 0a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM320 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm32-80c-32.8 0-61 19.7-73.3 48L32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l246.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48l54.7 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-54.7 0c-12.3-28.3-40.5-48-73.3-48zM192 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm73.3-64C253 35.7 224.8 16 192 16s-61 19.7-73.3 48L32 64C14.3 64 0 78.3 0 96s14.3 32 32 32l86.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 128c17.7 0 32-14.3 32-32s-14.3-32-32-32L265.3 64z" /></svg>*/}
                {/*</button>*/}
            </div>

            {/*Main*/}
            <div ref={mainRef!} onScroll={onScroll} class="grow overflow-y-auto px-4 pt-2">
                <div class="font-semibold tracking-wide text-gray-400 uppercase mb-1">Favoriten</div>
                {/*<div class="divide-y divide-gray-100 bg-white rounded-lg overflow-hidden shadow-lg mb-5">*/}
                {/*    <A href={`#`} class="flex items-center h-10 pr-1 relative">*/}
                {/*        <div class="flex w-full h-full items-center">*/}
                {/*            <svg class="mx-2.5 w-6 fill-rose-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z" /></svg>*/}
                {/*            <div class="flex-1 line-clamp-1">Favoriten</div>*/}
                {/*            <div class="my-auto">*/}
                {/*                <svg class="h-4 px-2 fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </A>*/}
                {/*    <A href={`#`} class="flex items-center h-10 pr-1 relative">*/}
                {/*        <div class="flex w-full h-full items-center">*/}
                {/*            <svg class="mx-2.5 w-6 fill-cantico-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M498.7 6c8.3 6 13.3 15.7 13.3 26V96c0 13.8-8.8 26-21.9 30.4L416 151.1V432c0 44.2-50.1 80-112 80s-112-35.8-112-80s50.1-80 112-80c17.2 0 33.5 2.8 48 7.7V128 64c0-13.8 8.8-26 21.9-30.4l96-32C479.6-1.6 490.4 0 498.7 6zM32 64H256c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96S14.3 64 32 64zm0 128H256c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 128h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32z"/></svg>*/}
                {/*            <div class="flex-1 line-clamp-1">Eigene Listen</div>*/}
                {/*            <div class="my-auto">*/}
                {/*                <svg class="h-4 px-2 fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </A>*/}
                {/*</div>*/}

                {/*<div class="font-semibold tracking-wide text-gray-400 uppercase mb-1">Freie Sammlungen</div>*/}
                <div class="divide-y divide-gray-100 bg-white rounded-lg overflow-hidden isolate shadow-lg mb-5">
                    <For each={books()?.filter(book => book.iapId === '')}>{(book: Book) => (
                        <A href={`/collection/${book.id}`} class="flex items-center h-10 pr-1 relative">
                            <div class="flex w-full h-full items-center">
                                <BookImage book={book} class="w-10 h-10 object-cover"/>
                                    <div class="flex-1 pl-1 line-clamp-1">{book.title}</div>
                                    <div class="my-auto">
                                        <svg class="h-4 px-2 fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
                                    </div>
                            </div>
                        </A>
                    )}</For>
                </div>

            </div>

            {/*Bottom bar*/}
            <div class="shrink-0">
                <BottomNavigation/>
            </div>

        </div>
    );
}

export default Favorites;