import {Preferences} from '@capacitor/preferences';
import {Directory, Filesystem} from '@capacitor/filesystem';

import {CapacitorSQLite, SQLiteConnection, SQLiteDBConnection} from '@capacitor-community/sqlite';
import {Capacitor} from "@capacitor/core";

const sqlite: SQLiteConnection = new SQLiteConnection(CapacitorSQLite)

/**
 * Opens a new or existing connection to the database.
 * @param dbName The name of the database.
 * @param encrypted Whether the database should be encrypted.
 * @param mode The mode of the database.
 * @param version The version number of the database.
 * @returns A promise that resolves with the database connection.
 */
export const openConnection = async (
    dbName: string,
    encrypted: boolean,
    mode: string,
    version: number
): Promise<SQLiteDBConnection> => {
    try {
        const retCC = (await sqlite.checkConnectionsConsistency()).result;
        let isConn = (await sqlite.isConnection(dbName, false)).result;

        let db: SQLiteDBConnection;
        if (retCC && isConn) {
            db = await sqlite.retrieveConnection(dbName, false);
        } else {
            db = await sqlite.createConnection(dbName, encrypted, mode, version, false);
        }

        await db.open();
        return db;
    } catch (err) {
        return Promise.reject(err);
    }
}


/**
 * Deletes the actual SQLite database file
 */
export async function deleteDatabase(db: SQLiteDBConnection) {
    try {
        await db.close();
        await Filesystem.deleteFile({
            path: 'Library/CapacitorDatabase/canticoSQLite.db',
            directory: Directory.Documents
        });
        await Preferences.remove({key: 'databaseSyncDate'})
        console.debug('Database deleted successfully');
    } catch (error) {
        console.error('Could not delete Database', error);
    }
}


export const db: SQLiteDBConnection | undefined = Capacitor.isNativePlatform() ? await openConnection("cantico", false, 'no-encryption', 1) : undefined
