import {Component, createEffect, createSignal, onCleanup, Show} from "solid-js";
import {Haptics, ImpactStyle} from '@capacitor/haptics';
import {binarySearch, cumulativeSum, map} from "../../utils/misc";
import {Capacitor} from "@capacitor/core";

const SNAP_THRESHOLD = 4; // px

interface Props {
    class: string;
    trackDurations: number[];
    trackNames?: string[];
    track: number;
    time: number;
    onInput: (track: number, time: number) => void,
}

export const SegmentedAudioSlider: Component<Props> = (props: Props) => {
    const [isDragging, setIsDragging] = createSignal(false);
    const [localTrack, setLocalTrack] = createSignal(props.track);
    const [localTime, setLocalTime] = createSignal(props.time);

    const cumulativeTrackTimes = () => cumulativeSum(props.trackDurations)

    let sliderRef: HTMLDivElement;
    let rootRef: HTMLDivElement;
    let cursorRef: HTMLDivElement;
    let textRef: HTMLDivElement;

    let isSnapped = false;

    createEffect(() => {
        if (!isDragging()) {
            setLocalTrack(props.track);
            setLocalTime(props.time);
        }
    })

    const totalDuration = () => {
        if (props.trackDurations.length > 0)
            return props.trackDurations.reduce((a, b) => a + b);
        return 1
    }

    const totalPercent = () => {
        let val = 0;
        for (let i = 0; i < localTrack(); i++) {
            val += props.trackDurations[i];
        }
        val += localTime();
        return val * 100 / totalDuration();
    }

    onCleanup(() => {
        document.removeEventListener('mousemove', onMouseOrTouchMove);
        document.removeEventListener('mouseup', onMouseOrTouchEnd);
        document.removeEventListener('touchmove', onMouseOrTouchMove);
        document.removeEventListener('touchend', onMouseOrTouchEnd);
    })

    const onMouseOrTouchStart = (e: MouseEvent | TouchEvent) => {
        setIsDragging(true);
        document.addEventListener('mousemove', onMouseOrTouchMove);
        document.addEventListener('mouseup', onMouseOrTouchEnd);
        document.addEventListener('touchmove', onMouseOrTouchMove);
        document.addEventListener('touchend', onMouseOrTouchEnd);
        onMouseOrTouchMove(e);
    }

    const onMouseOrTouchMove = (e: MouseEvent | TouchEvent) => {
        if (isDragging()) {
            const rect = sliderRef.getBoundingClientRect();
            const sliderLeft = rect.left;
            const sliderRight = rect.right;
            const eventX = ('touches' in e) ? e.touches[0].pageX : e.pageX;
            const clampedX = Math.max(
                Math.min(eventX, sliderRight),
                sliderLeft
            );

            const globalTime = map(clampedX, sliderLeft, sliderRight, 0, totalDuration());
            let track = binarySearch(cumulativeTrackTimes(), globalTime);
            const endPreviousTrack = track == 0 ? 0 : cumulativeTrackTimes()[track - 1];
            let time = globalTime - endPreviousTrack;

            // Snap to track
            const trackBoundaries = cumulativeTrackTimes().map(time => map(time, 0, totalDuration(), sliderLeft, sliderRight));
            const distanceToNextTrack = track < trackBoundaries.length ? Math.abs(trackBoundaries[track] - clampedX) : Number.MAX_VALUE;
            const distanceToPreviousTrack = track > 0 ? Math.abs(clampedX - trackBoundaries[track - 1]) : clampedX - sliderLeft;

            if (distanceToNextTrack < SNAP_THRESHOLD && track !== props.trackDurations.length - 1) {
                track++;
                time = 0;
                if (!isSnapped && Capacitor.getPlatform() === "ios") {
                    Haptics.impact({style: ImpactStyle.Light});
                }
            } else if (distanceToPreviousTrack < SNAP_THRESHOLD) {
                time = 0;
                if (!isSnapped && Capacitor.getPlatform() === "ios") {
                    Haptics.impact({style: ImpactStyle.Light});
                }
            }
            isSnapped = distanceToPreviousTrack < SNAP_THRESHOLD || distanceToNextTrack < SNAP_THRESHOLD;

            setLocalTrack(track);
            setLocalTime(time);
        }
    }

    const onMouseOrTouchEnd = (e: MouseEvent | TouchEvent) => {
        props.onInput(localTrack(), localTime());
        document.removeEventListener('mousemove', onMouseOrTouchMove);
        document.removeEventListener('mouseup', onMouseOrTouchEnd);
        document.removeEventListener('touchmove', onMouseOrTouchMove);
        document.removeEventListener('touchend', onMouseOrTouchEnd);
        setIsDragging(false);
    }

    return (
        <div class={props.class}
             onMouseDown={onMouseOrTouchStart}
             onTouchStart={onMouseOrTouchStart}
        >
            <div ref={rootRef!} class="relative py-3 flex items-center mx-2.5">
                <div class="absolute w-full flex flex-row space-x-0.5" ref={sliderRef!}>
                    {props.trackDurations.map((trackDuration, index) => (
                        <div class={`my-3 h-1 rounded-full ${index < localTrack() ? 'bg-cantico-800' : 'bg-gray-300'}`}
                             style={`width: ${trackDuration * 100 / totalDuration()}%`}
                        >
                            {index === localTrack() &&
                                <div class="h-1 bg-cantico-800 rounded-l-full"
                                     style={`width: ${localTime() * 100 / trackDuration}%`}
                                />
                            }
                            <div class="absolute -top-0.5 h-8"
                                 style={`width: ${trackDuration * 100 / totalDuration()}%`}
                            />
                        </div>
                    ))}
                </div>
                <Show when={isDragging()}>
                    <div class="absolute -top-0.5 left-0 w-full pointer-events-none">
                        <div ref={cursorRef!}
                             class="absolute h-7 w-0.5 bg-cantico-800"
                             style={`left: ${totalPercent()}%`}>
                            <Show when={props.trackNames}>
                                <div ref={textRef!}
                                     class={`absolute ${totalPercent() > 50 ? 'right-1' : 'left-1'} -bottom-1 text-cantico-800 text-xs whitespace-nowrap`}>{props.trackNames![localTrack()]}</div>
                            </Show>
                        </div>

                    </div>
                </Show>
                <Show when={!isDragging()}>
                    <div class="absolute top-0.5 -left-2.5 w-full pointer-events-none">
                        <div class="absolute h-5 w-5 rounded-full bg-cantico-800" style={`left: ${totalPercent()}%`}/>
                    </div>
                </Show>
            </div>
        </div>
    )
}