import {Component, createEffect, createSignal, For, JSXElement, onCleanup, onMount} from "solid-js";
import {createVirtualizer, Virtualizer} from "@tanstack/solid-virtual";
import {dao, Song} from "../../data";
import {Capacitor} from "@capacitor/core";
import SongListItem from "./SongListItem";
import {useNavigate} from "@solidjs/router";
import {Network} from "@capacitor/network";
import {Dialog} from "@capacitor/dialog";
import {downloadFiles} from "../../data/cache";

interface Props {
    songs: () => Song[]
    isBookOwned: () => boolean;
    scrollRef: HTMLDivElement;
    setDownloadAllSongsFunc: (func: () => void) => void;
    filter: string;
}

const SongList: Component<Props> = (props: Props) => {
    const navigate = useNavigate();
    const [lastSelectedSongId, setLastSelectedSongId] = createSignal("");
    const [downloadProgresses, setDownloadProgresses] = createSignal(new Map<string, number>());
    const [isDownloadAllowed, setIsDownloadAllowed] = createSignal(true);
    const [filter, setFilter] = createSignal("");

    const setDownloadProgress = (song: Song, value: number) => {
        setDownloadProgresses(prevProgresses => {
            const newProgresses = new Map(prevProgresses);
            newProgresses.set(song.id, value);
            return newProgresses;
        })
    }

    let view: JSXElement

    onMount(() => {
        setIsDownloadAllowed(true);
    })

    onCleanup(() => {
        setIsDownloadAllowed(false);
        setLastSelectedSongId('');
    })

    const downloadAllSongs = async() => {
        const songList = props.songs().filter(song => !song.isDownloaded);
        for (let song of songList) {
            if(isDownloadAllowed()) {
                await downloadSong(song);
            }
        }
    }
    props.setDownloadAllSongsFunc(downloadAllSongs);

    const downloadSong = async (song: Song) => {
        // TODO move much of this stuff to dao
        setDownloadProgress(song, 0.05);
        const musicFileDict = await dao.fetchSongMusicUrls(song.id);
        const musicUrls = Object.values(musicFileDict);
        await downloadFiles(musicUrls, (e => {
            setDownloadProgress(song, Math.max(e.progress || 0, 0.05));
        }))
        await dao.fetchSongData(song.id);
        song.isDownloaded = true;
        dao.setIsSongDownloaded(song.id, true);
    }
    const onSongClick = async (song: Song) => {
        if (props.isBookOwned()) {
            if (song.isDownloaded) {
                navigate(`/song/${song.id}`);
            } else {
                if ((await Network.getStatus()).connected) {
                    const progress = downloadProgresses().get(song.id);
                    setLastSelectedSongId(song.id);
                    if (progress && progress > 0) {
                        return
                    }
                    await downloadSong(song);
                    if(lastSelectedSongId() === song.id) {
                        navigate(`/song/${song.id}`);
                    }
                } else {
                    // TODO Eventell Dialog in eigenem Design?
                    await Dialog.alert({
                        title: "Keine Internetverbindung",
                        message: "Dieses Lied ist noch nicht heruntergeladen worden. Sie brauchen eine aktive Internetverbindung, um es öffnen zu können."
                    })
                }

            }

        }
    }

    if (Capacitor.getPlatform() === 'ios') {
        const [virtualizer, setVirtualizer] = createSignal<Virtualizer<any, any>>()
        const virtualItems = () => virtualizer() ? virtualizer()!.getVirtualItems() : [];

        onMount(() => {
            createEffect(() => {
                setVirtualizer(createVirtualizer({
                    count: props.songs().length,
                    estimateSize: () => 66,
                    overscan: 30,
                    getScrollElement: () => props.scrollRef,
                    measureElement: (el) => el.clientHeight,
                }))
            })
        })

        view =
            <div
                class="relative divide-y divide-cantico-50 w-full flex flex-col bg-white shadow-lg text-left rounded-lg"
                style={{
                    height: `${(virtualizer()?.getTotalSize() ?? 0)}px`,
                    'margin-bottom': "max(env(safe-area-inset-bottom), 0.75rem)",
                }}
            >
                {virtualItems().map(virtualItem => {
                    const song = props.songs()[virtualItem.index];
                    return (
                        <div
                            data-index={virtualItem.index}
                            ref={(el) => onMount(() => virtualizer()!.measureElement(el))}
                            class="cursor-pointer absolute top-0 left-0 w-full"
                            style={{transform: `translateY(${virtualItem.start}px)`}}
                            onClick={() => onSongClick(song)}
                        >
                            <SongListItem song={song} downloadProgress={downloadProgresses().get(song.id)} isBookOwned={props.isBookOwned()}/>
                        </div>
                    )
                })}
            </div>
    } else {
        view =
            <div
                class="relative flex flex-col w-full divide-y divide-cantico-50 bg-white shadow-lg text-left rounded-lg"
                style={{'margin-bottom': "max(env(safe-area-inset-bottom), 0.75rem)"}}
            >
                <For each={props.songs().filter(song => (props.filter == "" || song.title.toLowerCase().includes(props.filter.toLowerCase()) || song.number.toLowerCase().includes(props.filter.toLowerCase())))}>{(song: Song) => (
                    <div class={props.isBookOwned() ? "cursor-pointer" : "cursor-default"}
                         onClick={() => onSongClick(song)}
                    >
                        <SongListItem song={song} downloadProgress={downloadProgresses().get(song.id)} isBookOwned={props.isBookOwned()}/>
                    </div>
                )}</For>

            </div>
    }


    return view;
}

export default SongList;