import {Component, createEffect, createResource, createSignal, onCleanup, onMount, Show, Suspense} from 'solid-js';
import {useParams} from '@solidjs/router';
import {BookImage} from "../BookImage";
import {dao, Book as BookType} from "../../data";
import SongList from "./SongList";
import {appStore, order} from "../../iap";
import {Capacitor} from "@capacitor/core";
import {makeResizeObserver} from "@solid-primitives/resize-observer";

const Book: Component = () => {
  const params = useParams()
  const [isScrolled, setIsScrolled] = createSignal(false);

  // const [book] = createResource(params.id, dao.fetchBook.bind(dao));
  const [songs] = createResource(params.id, dao.fetchSongs.bind(dao), {initialValue: []});
  const [product, setProduct] = createSignal<CdvPurchase.Product>();
  const [isPurchaseOngoing, setIsPurchaseOngoing] = createSignal(false);
  const [book, setBook] = createSignal<BookType>();
  const [showDescription, setShowDescription] = createSignal(false);
  const [filter, setFilter] = createSignal("");

  let mainRef: HTMLDivElement;
  let descriptionRef: HTMLDivElement;

  const loadBook = async () => {
    setBook(await dao.fetchBook(params.id))
  };
  loadBook();

  createEffect(() => {
    if (book() && appStore) {
      setProduct(appStore.get(book()!.iapId));
    }
  })

  const {observe, unobserve} = makeResizeObserver(() => {
    const height = descriptionRef.offsetHeight;
    if (height > 0) {
      setShowDescription(showDescription() || height <= 120);
    }
  }, {box: "content-box"});

  onMount(() => {
    observe(descriptionRef);
  })

  onCleanup(() => {
    unobserve(descriptionRef);
  })


  const isBookOwned = () => {
    return book()?.isPurchased || book()?.iapId === "";
    // return true; // TODO remove
  }

  const onScroll = () => {
    setIsScrolled(mainRef.scrollTop > 0);
  }

  let downloadAllSongs: () => void;
  const setDownloadAllSongsFunc = (func: () => void) => {
    downloadAllSongs = func;
  }

  const onHandleDownloadAll = () => {
    if (downloadAllSongs) {
      downloadAllSongs();
    }
  }

  const handleOrder = () => {
    if (!isPurchaseOngoing()) {
      setIsPurchaseOngoing(true);
      order(
        product(),
        () => {
          setIsPurchaseOngoing(false);
          loadBook();
        }
      )
    }
  }

  return (
    <div class="flex flex-col h-full bg-cantico-50 overflow-hidden">
      {/*Top bar*/}
      <div class={`${isScrolled() ? "shadow-lg" : "shadow-none"} transition-shadow duration-300 z-10 shrink-0 flex items-center w-full py-2 px-4 safe-top`}>
        <button class="relative rounded-lg shadow-md bg-white h-10 w-10 shrink-0 flex items-center justify-center" onClick={() => history.back()}>
          <svg class="h-5 fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/>
          </svg>
        </button>
        <div class="ml-2 relative rounded-lg shadow-md w-full grow">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <svg class="fill-gray-400 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
            </svg>
          </div>
          <input type="text" class="focus:outline-0 focus:ring-0 block w-full rounded-lg border-0 py-2 pl-10 bg-white placeholder:text-gray-300" placeholder="Filtern..." onInput={e=> setFilter(e.target.value)}/>
        </div>
      </div>

      {/*Main*/}
      <div ref={mainRef!} onScroll={onScroll} class="grow overflow-y-auto h-full px-4 pt-2">

        <Suspense fallback={
          <div class="flex flex-col items-center justify-center w-full h-full">
            <svg class="h-32 w-32 fill-gray-300 animate-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"/>
            </svg>
          </div>
        }>
          <div class="flex flex-col items-center">
            <BookImage
              class="h-48 bg-gray-500 rounded-lg shrink-0 overflow-hidden shadow-lg shadow-gray-700/40"
              // @ts-ignore
              book={book()}
              size={384}
            />
            <h1 class="mt-2 text-center font-bold text-lg">{book()?.title}</h1>
            <h2 class="text-center pb-3">{book()?.subtitle}</h2>
          </div>

          <Show when={!isBookOwned()}>
            <button
              class={`${isPurchaseOngoing() ? "bg-cantico-800/50" : "bg-cantico-800"} shadow-lg mt-2 mb-4 flex items-center justify-center w-full px-2 py-2 font-semibold rounded-lg text-white`}
              onClick={() => {
                Capacitor.isNativePlatform() ? handleOrder() : null
              }}
            >
              <div class={`${isPurchaseOngoing() ? "animate-spin" : ""}`}>
                <svg class="h-6 fill-current" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
                  <path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/>
                </svg>
              </div>
              <Show when={Capacitor.isNativePlatform()}>
                <span class="ml-3">Sammlung kaufen für {product()?.pricing?.price}</span>
              </Show>
              <Show when={!Capacitor.isNativePlatform()}>
                <span class="ml-3">Sammlung nur in der App erhältlich</span>
              </Show>
            </button>
          </Show>

          <Show when={book()?.description !== ''}>
            <div class="font-semibold tracking-wide text-gray-400 uppercase">Beschreibung</div>
            <div class={`${showDescription() ? "" : "cursor-pointer"} mb-3`} onClick={() => setShowDescription(true)}>
              <div class={`${!showDescription() ? "h-24 overflow-hidden" : ""} relative`}>
                <div ref={descriptionRef!} class={`${!showDescription() ? "fade-out" : ""}`} innerHTML={book()?.description}/>
              </div>
              <Show when={!showDescription()}>
                <button class="mx-auto w-full text-cantico-800 text-sm">
                  Mehr
                </button>
              </Show>
            </div>
          </Show>

          <div class="font-semibold tracking-wide text-gray-400 uppercase mb-1">Lieder</div>
          <Show when={Capacitor.isNativePlatform() && isBookOwned() && songs().length > 1 && !songs().map(song => song.isDownloaded).every(Boolean)}>
            <button
              class="shadow-lg mb-2 bg-white flex items-center justify-center w-full px-2 py-2 font-semibold rounded-lg text-cantico-800"
              onClick={onHandleDownloadAll}
            >
              <svg class="h-6 fill-cantico-800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z"/>
              </svg>
              <span class="ml-3">Alle Lieder herunterladen</span>
            </button>
          </Show>
          <SongList filter={filter()} songs={songs} isBookOwned={isBookOwned} scrollRef={mainRef!} setDownloadAllSongsFunc={setDownloadAllSongsFunc}/>


          <div class="font-semibold tracking-wide text-gray-400 uppercase mt-4">Rechtliche Hinweise</div>
          <div class="mb-3" innerHTML={book()?.legal || "<p>Liedrechte am jeweiligen Lied ersichtlich.</p>"}/>

        </Suspense>
      </div>

    </div>
  )
}

export default Book;