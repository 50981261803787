import {A} from "@solidjs/router";
import {Component, createEffect, createSignal, For} from "solid-js";
import {dao} from "../data";
import {BookImage} from "./BookImage";
import {SearchResult} from "../data/types";

interface Props {
  query: string
}


const SearchResults: Component<Props> = (props: Props) => {
  const [isScrolled, setIsScrolled] = createSignal(false);
  const [results, setResults] = createSignal<SearchResult[]>([]);

  let mainRef: HTMLDivElement;

  const onScroll = (event: Event) => {
    setIsScrolled(mainRef.scrollTop > 0);
  }

  createEffect(async () => {
    setResults(await dao.search(props.query));
  })


  return (

    <div ref={mainRef!} onScroll={onScroll} class="grow overflow-y-auto">
      <div class="font-semibold tracking-wide text-gray-400 uppercase mb-1">Suchergebnisse</div>
      <div class="divide-y divide-gray-100 bg-white rounded-lg overflow-hidden isolate shadow-lg mb-5">
        <For each={results().filter(s => s.isAvailable)}>{(song: SearchResult) => (
          <A href={`/song/${song.songId}`} class="flex items-center h-10 pr-1 relative">
            <div class="flex w-full h-full items-center">
              <BookImage book={{id: song.bookId, cover: song.cover}} class="w-10 h-10 object-cover"/>
              <div class="flex-1 pl-1 line-clamp-1">{song.number} {song.title}</div>
              <div class="my-auto">
                <svg class="h-4 px-2 fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/>
                </svg>
              </div>
            </div>
          </A>
        )}</For>
      </div>

    </div>
  );
}

export default SearchResults;