import {Component, createEffect, createSignal, onCleanup, onMount} from "solid-js";
import {isFullscreen, setIsFullscreen} from "../../utils/platform";
import {Capacitor} from "@capacitor/core";

import {worker as vrvWorker} from "../../score";

const SINGLE_TAP_THRESHOLD = 10;
const DEFAULT_SCALE: number = 40;


interface Props {
    class?: string;
    mei: string;
    scale?: number;
}

export const ScoreViewer: Component<Props> = (props: Props) => {
    const [isMouseDown, setIsMouseDown] = createSignal(false);
    const [isLoading, setIsLoading] = createSignal(true);
    let scoreRef: HTMLDivElement;

    let width: number;
    let height: number;
    let scale = props.scale || DEFAULT_SCALE;
    let initialX: number | null = null;
    let initialY: number | null = null;
    let initialDistance: number | null = null;
    let currentDistance: number | null = null;
    let isPinchGesture = false;
    let scrollLeft: number;

    const resizeObserver = new ResizeObserver((entries) => {
        renderScore();
    })

    const setCurrentPage = (num: number) => {
        scoreRef.scrollTo({
            left: (num - 1) * scoreRef.clientWidth,
            behavior: "instant"
        });
    }

    const getCurrentPage = () => {
        return Math.round(scoreRef.scrollLeft / scoreRef.clientWidth) + 1;
    }

    const getFirstMeasureIdOnPage = (pageNum: number) => {
        if (scoreRef.children.length > pageNum - 1) {
            const svgElement = scoreRef.children[pageNum - 1];
            const gElement = svgElement.querySelector('g.measure');
            if (gElement) {
                return gElement.id;
            }
        }
    }

    const onTouchStart = (e: TouchEvent) => {
        if (e.touches.length > 1) {
            e.preventDefault();
        }

        initialX = e.touches[0].clientX;
        initialY = e.touches[0].clientY;

        if (e.touches.length > 1) {
            initialDistance = Math.hypot(
                e.touches[0].pageX - e.touches[1].pageX,
                e.touches[0].pageY - e.touches[1].pageY
            );
            isPinchGesture = true;
            console.log('initDist', initialDistance)
        }
    }
    const onTouchMove = (e: TouchEvent) => {
        if (e.touches.length > 1 && initialDistance !== null) {
            e.preventDefault();
            currentDistance = Math.hypot(
                e.touches[0].pageX - e.touches[1].pageX,
                e.touches[0].pageY - e.touches[1].pageY
            );
            let scaleFactor = (currentDistance / initialDistance);
            const minScale = 25;
            const maxScale = scoreRef.clientWidth / 7;
            let newScale = scale * scaleFactor
            newScale = Math.min(maxScale, newScale);
            newScale = Math.max(minScale, newScale);
            scaleFactor = newScale / scale;
            scoreRef.style.scale = scaleFactor.toString()
        }
    }
    const onTouchEnd = (e: TouchEvent) => {
        if (initialX === null || initialY === null) {
            return;
        }

        const diffX = initialX - e.changedTouches[0].clientX;
        const diffY = initialY - e.changedTouches[0].clientY;
        if (Math.abs(diffX) < SINGLE_TAP_THRESHOLD && Math.abs(diffY) < SINGLE_TAP_THRESHOLD) {
            setIsFullscreen(!isFullscreen());
        } else if (isPinchGesture) {
            scale = scale * parseFloat(scoreRef.style.scale);
            renderScore();
            scoreRef.style.scale = '1.0';
            isPinchGesture = false;
        }
    }

    const onMouseDown = (e: MouseEvent) => {
        setIsMouseDown(true);
        initialX = e.pageX - scoreRef.offsetLeft;
        scrollLeft = scoreRef.scrollLeft;
    }

    const onMouseMove = (e: MouseEvent) => {
        if (isMouseDown()) {
            e.preventDefault();
            const x = e.pageX - scoreRef.offsetLeft;
            const scroll = x - initialX!;
            scoreRef.scrollLeft = scrollLeft - scroll;
        }
    }

    const onMouseUp = () => {
        setIsMouseDown(false);
    }

    onMount(() => {
        resizeObserver.observe(scoreRef);

        scoreRef.addEventListener('touchstart', onTouchStart, false);
        scoreRef.addEventListener('touchmove', onTouchMove, false);
        scoreRef.addEventListener('touchend', onTouchEnd, false);

        scoreRef.addEventListener('mousedown', onMouseDown, false);
        scoreRef.addEventListener('mousemove', onMouseMove, false);
        scoreRef.addEventListener('mouseup', onMouseUp, false);
        scoreRef.addEventListener('mouseleave', onMouseUp, false);

        onCleanup(() => {
            scoreRef.removeEventListener('touchstart', onTouchStart);
            scoreRef.removeEventListener('touchmove', onTouchMove);
            scoreRef.removeEventListener('touchend', onTouchEnd);

            scoreRef.removeEventListener('mousedown', onMouseDown);
            scoreRef.removeEventListener('mousemove', onMouseMove);
            scoreRef.removeEventListener('mouseup', onMouseUp);
            scoreRef.removeEventListener('mouseleave', onMouseUp);
        })
    });

    onCleanup(() => {
        scoreRef.innerHTML = '';
    })

    async function renderScore() {
        setIsLoading(true);
        const style = getComputedStyle(scoreRef)
        width = scoreRef.clientWidth;
        height = scoreRef.clientHeight - parseInt(style.marginTop) - parseInt(style.marginBottom);
        const margin = isFullscreen() && Capacitor.isNativePlatform() ? 8 * 100 / scale : 16 * 100 / scale;

        vrvWorker.postMessage({
            cmd: "renderToSVG",
            param: {
                scale,
                pageWidth: width * 100 / scale,
                pageHeight: height * 100 / scale,
                pageMarginBottom: margin,
                pageMarginTop: margin,
                pageMarginLeft: 16 * 100 / scale,
                pageMarginRight: 16 * 100 / scale,
            }
        })
    }

    createEffect(() => {
        if (props.mei !== '') {
            vrvWorker.postMessage({cmd: 'loadData', param: props.mei});
        }
    });

    vrvWorker.onmessage = function (event) {
        if (event.data.cmd === 'renderToSVG') {
            scoreRef.innerHTML = event.data.result.join(""); // result should be array of SVGs

            // Get measure ID from first measure of currently shown page
            const firstMeasureIdOnPage = getFirstMeasureIdOnPage(getCurrentPage());
            // Jump approximately to previously shown page
            if (firstMeasureIdOnPage) {
                vrvWorker.postMessage({cmd: 'getPageWithElement', param: firstMeasureIdOnPage});
            }
        } else if (event.data.cmd === 'getPageWithElement') {
            const pageNumWithMeasure = event.data.result; // result should be the page number
            if (pageNumWithMeasure) {
                setCurrentPage(pageNumWithMeasure);
            }
            setIsLoading(false);
        } else if (event.data.cmd === 'loadData') {
            renderScore();
        }
    }

    return (
        <>
            <div
                class={`${props.class} ${!isLoading() ? "hidden" : ""} h-full w-full flex items-center justify-center`}>
                <svg class="h-32 w-32 fill-gray-300 animate-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V96c0 17.7 14.3 32 32 32s32-14.3 32-32V32zm0 384c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 17.7 14.3 32 32 32s32-14.3 32-32V416zM0 256c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32s-14.3-32-32-32H32c-17.7 0-32 14.3-32 32zm416-32c-17.7 0-32 14.3-32 32s14.3 32 32 32h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H416zM75 75c-12.5 12.5-12.5 32.8 0 45.3l45.3 45.3c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L120.2 75C107.7 62.5 87.5 62.5 75 75zM391.8 346.5c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L391.8 437c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-45.3-45.3zM75 437c12.5 12.5 32.8 12.5 45.3 0l45.3-45.3c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L75 391.8c-12.5 12.5-12.5 32.8 0 45.3zM346.5 120.2c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L437 120.2c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-45.3 45.3z"/></svg>
            </div>
            <div
                class={`${isLoading() ? "absolute -left-full" : ""} ${props.class} ${!isMouseDown() ? "snap-mandatory snap-x" : ""} ${isFullscreen() ? "safe-top safe-bottom" : ""} overflow-x-auto flex flex-row no-scrollbar w-full`}
                ref={scoreRef!}
            />
        </>
    )
}