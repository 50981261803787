import {createEffect, createResource, createSignal} from "solid-js";
import {getUriFromCacheOrDownload} from "../data";
import {Book} from "../data";
import {Capacitor} from "@capacitor/core";

interface BookImageProps {
  book: {
    id: string,
    cover: string
  };
  class: string;
  size?: number
}

const fetchImage = async (props: BookImageProps) => {
  let src = `${import.meta.env.VITE_BACKEND_HOST}/api/files/books/${props.book.id}/${props.book.cover}?thumb=${props.size ?? 80}x${props.size ?? 80}`;
  if (Capacitor.isNativePlatform()) {
    src = Capacitor.convertFileSrc(await getUriFromCacheOrDownload(src));
  }
  return src;
}

export function BookImage(props: BookImageProps) {
  const [src, setSrc] = createSignal('');

  createEffect(async () => {
    if (props.book) {
      setSrc(await fetchImage(props))
    }
  })

  return (
    <img class={props.class} src={src()}/>
  );
}