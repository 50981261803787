export const base_schema: string = `
CREATE TABLE IF NOT EXISTS 'books' (
    -- Server columns
    'id'          TEXT PRIMARY KEY,
    'created'     TEXT,
    'cover'       TEXT,
    'description' TEXT,
    'hasMusic'    BOOLEAN,
    'iapId'       TEXT,
    'isVisible'   BOOLEAN,
    'legal'       TEXT,
    'sorting'     NUMERIC,
    'status'      TEXT,
    'subtitle'    TEXT,
    'title'       TEXT,
    'updated'     TEXT,
    
    -- Client columns
    'isPurchased' BOOLEAN DEFAULT FALSE,
    'lastOpened'  TEXT DEFAULT ''
);

CREATE TABLE IF NOT EXISTS 'songs' (
    -- Server columns
    'rowid'           INTEGER PRIMARY KEY,
    'id'              TEXT,
    'book'            TEXT,
    'created'         TEXT,
    'isVisible'       BOOLEAN,
    'json'            TEXT,
    'language'        TEXT,
    'legal'           TEXT,
    'lyrics'          TEXT,
    'mei'             TEXT,
    'musicOggOpus'    TEXT,
    'musicCafOpus'    TEXT,
    'number'          TEXT,
    'title'           TEXT,
    'updated'         TEXT,
    
    -- Client columns
    'selectedParts'   TEXT DEFAULT '',
    'isDownloaded'    BOOLEAN DEFAULT FALSE,
    'isFavorite'      BOOLEAN DEFAULT FALSE
);

CREATE TABLE IF NOT EXISTS 'database' (
    'id'            INTEGER PRIMARY KEY,
    'schemaVersion' NUMERIC,
    'updated'       TEXT
);

CREATE TABLE IF NOT EXISTS 'playlists' (
    'id'   INTEGER PRIMARY KEY,
    'name' TEXT
);

CREATE TABLE IF NOT EXISTS 'playlistItems' (
    'id'            INTEGER PRIMARY KEY,
    'song'          TEXT,
    'selectedParts' TEXT,
    'sorting'       NUMERIC
);
`

export const fts_schema: string = `
CREATE VIRTUAL TABLE IF NOT EXISTS 'songSearch' USING fts5(
    'id' UNINDEXED,
    'number',
    'title',
    'lyrics',
    content='songs',
    content_rowid='rowid'
);

CREATE TRIGGER IF NOT EXISTS songs_ai AFTER INSERT ON songs BEGIN INSERT INTO songSearch(rowid, id, number, title, lyrics) VALUES (new.rowid, new.id, new.number, new.title, new.lyrics); END;
CREATE TRIGGER IF NOT EXISTS songs_ad AFTER DELETE ON songs BEGIN DELETE FROM songSearch WHERE rowid = old.rowid; END;
CREATE TRIGGER IF NOT EXISTS songs_au AFTER UPDATE ON songs BEGIN DELETE FROM songSearch WHERE rowid = old.rowid; INSERT INTO songSearch(rowid, id, number, title, lyrics) VALUES (new.rowid, new.id, new.number, new.title, new.lyrics); END;
`