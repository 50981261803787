import {Component, createSignal} from "solid-js";
import BottomNavigation from "../BottomNavigation";
import {dao} from "../../data";
import {SearchResult} from "../../data/types";
import SearchResults from "../SearchResults";

const Settings: Component = () => {
  const [query, setQuery] = createSignal("");

  let resultsRef: HTMLDivElement;
  const onInput = async (e: InputEvent & {currentTarget: HTMLInputElement, target: HTMLInputElement}) => {
    const results = await dao.search(e.target!.value);
    resultsRef.innerText = results.map((r: SearchResult) => `${r.rank}  ${r.number}. ${r.title}`).join('\n')
  }

  return (
    <div class="flex flex-col h-full bg-cantico-50 overflow-hidden">
      <div class="p-4 safe-top">
        {/*<input onInput={onInput}/>*/}
        <input onInput={(e) => {setQuery(e.target!.value)}}/>
      </div>
      <SearchResults query={query()}/>

      {/*Bottom bar*/}
      <div class="shrink-0">
        <BottomNavigation/>
      </div>
    </div>
  );
}

export default Settings;