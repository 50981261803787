import 'cordova-plugin-purchase';
import {Capacitor} from "@capacitor/core";
import {dao} from "../data";
import {createSignal} from "solid-js";

const [lastApprovedId, setLastApprovedId] = createSignal('');
let appStore: CdvPurchase.Store;
export {appStore, lastApprovedId};


export async function initializeStore() {
    let platform = {
        'android': CdvPurchase.Platform.GOOGLE_PLAY,
        'ios': CdvPurchase.Platform.APPLE_APPSTORE
    }[Capacitor.getPlatform()] ?? CdvPurchase.Platform.TEST;

    appStore = CdvPurchase.store;
    // appStore.verbosity = CdvPurchase.LogLevel.DEBUG;
    appStore.error(error => {
        console.error(error.code, error.message);
    })

    appStore.when()
        .productUpdated((product: CdvPurchase.Product) => {
            // console.debug(product.owned, product.title, product.id, product.pricing?.price);
        })
        .approved((transaction: CdvPurchase.Transaction) => {
            const id = transaction.products[0].id;
            dao.setIsBookPurchased(id, true).then(() => {setLastApprovedId(id)});
            transaction.verify();
        })
        .verified((receipt: CdvPurchase.VerifiedReceipt) => {
            receipt.finish();
        });

    const iapIds = await dao.getIapIds();
    const products = iapIds.map(iapId => ({
        id: iapId,
        type: CdvPurchase.ProductType.NON_CONSUMABLE,
        platform: platform
    }));
    appStore.register(products);

    await appStore.initialize();
    // console.debug(appStore.products.map(p => `${p.title} - ${p.pricing?.price}`));
}

export function order(product: CdvPurchase.Product | undefined, callbackFullfilled: () => void) {
    if (product && Capacitor.isNativePlatform()) {
        product.getOffer()?.order().then(callbackFullfilled);
    }
}