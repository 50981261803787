import { A } from "@solidjs/router";
import {Component, onCleanup, onMount} from "solid-js";
import {Capacitor} from "@capacitor/core";
import {NavigationBar} from "@hugotomazi/capacitor-navigation-bar";
import {StatusBar} from "@capacitor/status-bar";


const BottomNavigation: Component = () => {
    onMount(() => {
        if (Capacitor.getPlatform() === "android") {
            NavigationBar.setColor({color: "#24557F", darkButtons: false})
        }
    })

    // TODO Could be made better, causes flickering
    onCleanup(() => {
        if (Capacitor.getPlatform() === "android") {
            NavigationBar.setColor({color: "#F0F5FA", darkButtons: true})
        }
    })

    return (
        <div class="bg-cantico-800 w-full" style="padding-bottom: calc(env(safe-area-inset-bottom) / 2)">
            <div class="flex w-full md:max-w-sm text-xs text-white/50 justify-evenly mx-auto pt-1">
                <A href="/" end={true} activeClass="text-white fill-white" class="flex flex-col w-1/3 items-center justify-center h-16 space-y-1">
                    <svg class="h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" /></svg>
                    <span>Entdecken</span>
                </A>
                <A href="/Favorites" end={true} activeClass="text-white fill-white" class="flex flex-col w-1/3 items-center justify-center h-16 space-y-1">
                    <svg class="h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"/></svg>
                    <span>Favoriten</span>
                </A>
                <A href="/settings" end={true} activeClass="text-white fill-white" class="flex flex-col w-1/3 items-center justify-center h-16 space-y-1">
                    <svg class="h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 416c0 17.7 14.3 32 32 32l54.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 448c17.7 0 32-14.3 32-32s-14.3-32-32-32l-246.7 0c-12.3-28.3-40.5-48-73.3-48s-61 19.7-73.3 48L32 384c-17.7 0-32 14.3-32 32zm128 0a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM320 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm32-80c-32.8 0-61 19.7-73.3 48L32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l246.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48l54.7 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-54.7 0c-12.3-28.3-40.5-48-73.3-48zM192 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm73.3-64C253 35.7 224.8 16 192 16s-61 19.7-73.3 48L32 64C14.3 64 0 78.3 0 96s14.3 32 32 32l86.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 128c17.7 0 32-14.3 32-32s-14.3-32-32-32L265.3 64z" /></svg>
                    <span>Einstellungen</span>
                </A>
            </div>
        </div>
    );
}

export default BottomNavigation;