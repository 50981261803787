import {Capacitor} from "@capacitor/core";
import {StatusBar, Style} from "@capacitor/status-bar";
import {NavigationBar} from "@hugotomazi/capacitor-navigation-bar";
import {createEffect, createSignal, onCleanup} from 'solid-js';
import {initializeStore} from "../iap";

export const [isFullscreen, setIsFullscreen] = createSignal(false);
if (Capacitor.isNativePlatform()) {
    createEffect(() => {
        if (isFullscreen()) {
            document.documentElement.style.backgroundColor = '#ffffff';
            document.body.style.backgroundColor = '#ffffff';
            StatusBar.setStyle({style: Style.Light});
            if (Capacitor.getPlatform() === "android") {
                NavigationBar.setColor({color: "#ffffff", darkButtons: true})
                StatusBar.setBackgroundColor({color: "#ffffff"});
            }
        } else {
            document.documentElement.style.backgroundColor = '#F0F5FA';
            document.body.style.backgroundColor = '#F0F5FA';
            StatusBar.setStyle({style: Style.Light});
            if (Capacitor.getPlatform() === "android") {
                NavigationBar.setColor({color: "#F0F5FA", darkButtons: true})
                StatusBar.setBackgroundColor({color: "#F0F5FA"});
            }
        }
    })
}

export async function initPlatform() {
    if (Capacitor.isNativePlatform()) {
        setIsFullscreen(false);
        await initializeStore();
    }
}

export const setupBackSwipeNavigationIOS = (callback: () => void) => {
    if (Capacitor.getPlatform() === 'ios') {
        let xDown: number | null = null;
        let yDown: number | null = null;
        let xDiff: number = 0;
        let yDiff: number = 0;
        const leftBorderThreshold = 20;

        const handleTouchStart = (evt: TouchEvent) => {
            xDown = evt.touches[0].clientX;
            yDown = evt.touches[0].clientY;
        };

        const handleTouchMove = (evt: TouchEvent) => {
            if (!xDown || !yDown) {
                return;
            }

            const xUp = evt.touches[0].clientX;
            const yUp = evt.touches[0].clientY;

            xDiff = xDown - xUp;
            yDiff = yDown - yUp;

        };

        const handleTouchEnd = (evt: TouchEvent) => {
            if (Math.abs(xDiff) > Math.abs(yDiff)) {
                if (xDiff > 0) {
                    /* left swipe */
                } else {
                    /* right swipe */
                    if (xDown! < leftBorderThreshold) {
                        callback();
                    }
                }
            }

            /* reset values */
            xDown = null;
            yDown = null;
        };

        window.addEventListener('touchstart', handleTouchStart, false);
        window.addEventListener('touchmove', handleTouchMove, false);
        window.addEventListener('touchend', handleTouchEnd, false);

        onCleanup(() => {
            window.removeEventListener('touchstart', handleTouchStart, false);
            window.removeEventListener('touchmove', handleTouchMove, false);
            window.removeEventListener('touchend', handleTouchEnd, false);
        });
    }
};