import {Capacitor} from "@capacitor/core";
import pb from "./pocketbase";
import {base_schema, fts_schema} from "./schema";
import {Preferences} from "@capacitor/preferences";
import {Network} from "@capacitor/network";
import {capSQLiteSet, SQLiteDBConnection} from "@capacitor-community/sqlite";
import {downloadFiles, getUriFromCacheOrDownload, readFileFromCacheOrDownload} from "./cache";
import {Book, SongJson, Song, SearchResult} from "./types";

import {openConnection} from "./sqlite";
import {createSignal} from "solid-js";
import {getCurrentDateTime} from "../utils/misc";
import axios from "axios";
import {canPlayOggOpus} from "../audio";


// Collator for sorting in "natural" order
const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
const [databaseTimestamp, setDatabaseTimestamp] = createSignal('');

export abstract class AbstractDao {
  async syncDatabase(): Promise<void> {
    // No-op
    setDatabaseTimestamp(getCurrentDateTime());
    return Promise.resolve();
  }

  async getIapIds(): Promise<string[]> {
    // No-op
    return Promise.resolve([]);
  }

  abstract fetchBook(bookId: string): Promise<Book>

  abstract fetchBooks(): Promise<Book[]>

  abstract fetchSong(songId: string): Promise<Song>

  abstract fetchSongData(songId: string): Promise<SongJson>

  abstract fetchSongMusicUrls(songId: string): Promise<{ [key: string]: string }>

  abstract fetchSongs(bookId: string): Promise<Song[]>

  abstract search(string: string): Promise<SearchResult[]>

  async setIsBookPurchased(bookId: string, value: boolean): Promise<void> {
  }

  async setIsSongDownloaded(songId: string, value: boolean): Promise<void> {
  }
}

class WebDao extends AbstractDao {
  async fetchBook(bookId: string) {
    return await pb.collection("books").getOne(bookId) as Book;
  }

  async fetchBooks() {
    console.debug('[DAO] Fetching books from pocketbase...');
    return await pb.collection("books").getFullList({
      sort: '-sorting'
    }) as Book[];
  }

  async fetchSong(songId: string) {
    return await pb.collection("songs").getOne(songId) as Song;
  }

  async fetchSongData(songId: string) {
    const fileName = (await pb.collection("songs").getOne(songId) as Song).json;
    return await axios.get(`${import.meta.env.VITE_BACKEND_HOST}/api/files/songs/${songId}/${fileName}`).then(res => res.data)
  }

  async fetchSongMusicUrls(songId: string) {
    let key: 'musicOggOpus' | 'musicCafOpus' = canPlayOggOpus() ? 'musicOggOpus' : 'musicCafOpus';
    const fileNames: string[] = (await pb.collection("songs").getOne(songId))[key];
    const dict = fileNames.reduce((acc: { [key: string]: string }, f: string) => {
      acc[f.slice(0, f.lastIndexOf('_'))] = `${import.meta.env.VITE_BACKEND_HOST}/api/files/songs/${songId}/${f}`;
      return acc;
    }, {});
    return dict;
  }

  async fetchSongs(bookId: string) {
    const result = await pb.collection("songs").getFullList({
      filter: `book="${bookId}"`
    }) as Song[];
    // Sort in natural order by the field "number"
    result.sort((a, b) => collator.compare(a.number, b.number));
    return result;
  }

  async search(query: string): Promise<SearchResult[]> {
    if (query == "") {
      return [];
    }

    const songs = await pb.collection("songs").getList(1, 50, {
      filter: `title ?~ "${query}" || number = "${query}"`,
      expand: 'book',
      fields: 'id,number,title,expand.book.id,expand.book.cover,expand.book.title,expand.book.iapId',
      sort: 'book.title,number,title'
    });
    const results: SearchResult[] = songs.items.map((entry, index) => ({
      "songId": entry.id,
      "number": entry.number,
      "title": entry.title,
      "bookId": entry.expand!.book.id,
      "cover": entry.expand!.book.cover,
      "rank": index,
      "isAvailable": entry.expand!.book.iapId == ""
    }))
    return results
  }
}

class MobileDao extends AbstractDao {
  private db: SQLiteDBConnection;

  private constructor(db: SQLiteDBConnection) {
    super();
    this.db = db;
  }

  static async create(): Promise<MobileDao> {
    const db = await openConnection("cantico", false, 'no-encryption', 1);
    return new MobileDao(db);
  }

  private filterRecord(record: Record<string, unknown>): Record<string, unknown> {
    const {collectionId, collectionName, expand, ...filteredRecord} = record;
    return filteredRecord;
  }

  private async getNewUpdatedRecords(collection: string, databaseSyncDate: string) {
    const records = await pb.collection(collection).getFullList({
      filter: `updated > '${databaseSyncDate}'`, // ${collection==="books" ? "&& status = 'active'" : ""}`, // TODO take care of 'active' etc.
      sort: '-updated'
    });
    const filteredRecords = records.map(this.filterRecord);

    if (records.length > 0) {
      const recordsSet: Array<capSQLiteSet> = [{
        statement: `INSERT OR REPLACE INTO ${collection} (${Object.keys(filteredRecords[0]).join(',')}) VALUES (${Array(Object.keys(filteredRecords[0]).length).fill('?').join(',')});`,
        values: filteredRecords.map(record => {
          let values: any = Object.values(record);
          // Stringify if not a primitive data type
          values = values.map((v: any) => {
            return Array.isArray(v) ? JSON.stringify(v) : v;
          })
          return values;
        })
      }];

      await this.db.executeSet(recordsSet);

      // If collection is 'books', download and cache book covers
      if (collection === 'books') {
        await downloadFiles(records.map(record => `${import.meta.env.VITE_BACKEND_HOST}/api/files/books/${record.id}/${record.cover}?thumb=160x160`));
        downloadFiles(records.map(record => `${import.meta.env.VITE_BACKEND_HOST}/api/files/books/${record.id}/${record.cover}?thumb=80x80`));
        downloadFiles(records.map(record => `${import.meta.env.VITE_BACKEND_HOST}/api/files/books/${record.id}/${record.cover}?thumb=384x384`));
      }
    }

    return records;
  }

  async getIapIds() {
    const result = await this.db.query("SELECT iapId FROM books WHERE iapId!='';");
    return result.values!.map(v => v.iapId) as string[];
  }

  async fetchBook(bookId: string) {
    const result = await this.db.query(`SELECT * FROM books WHERE id='${bookId}'`);
    return result.values![0] as Book;
  }

  async fetchBooks() {
    const result = await this.db.query("SELECT * FROM books ORDER BY sorting DESC;");
    return result.values as Book[];
  }

  async fetchSong(songId: string) {
    const result = await this.db.query(`SELECT * FROM songs WHERE id='${songId}'`);
    return result.values![0] as Song;
  }

  async fetchSongData(songId: string) {
    const filename = (await this.db.query(`SELECT json FROM songs WHERE id='${songId}'`)).values![0].json;
    const path = `${import.meta.env.VITE_BACKEND_HOST}/api/files/songs/${songId}/${filename}`;
    const data = (await readFileFromCacheOrDownload(path)) as string;
    return JSON.parse(data);
  }

  async fetchSongMusicUrls(songId: string) {
    let key: 'musicOggOpus' | 'musicCafOpus' = canPlayOggOpus() ? 'musicOggOpus' : 'musicCafOpus';
    const result = (await this.db.query(`SELECT ${key} FROM songs WHERE id='${songId}'`)).values![0][key];
    let fileNames = JSON.parse(result);

    const dict = fileNames.reduce((acc: { [key: string]: string }, f: string) => {
      acc[f.slice(0, f.lastIndexOf('_'))] = `${import.meta.env.VITE_BACKEND_HOST}/api/files/songs/${songId}/${f}`;
      return acc;
    }, {});
    return dict;
  }

  async setIsSongDownloaded(songId: string, value: boolean) {
    console.log(`UPDATE songs SET isDownloaded=${value ? 1 : 0} WHERE id='${songId}';`)
    await this.db.execute(`UPDATE songs SET isDownloaded=${value ? 1 : 0} WHERE id='${songId}';`);
    console.log("done");
  }

  async setIsBookPurchased(iapId: string, value: boolean) {
    await this.db.execute(`UPDATE books SET isPurchased=${value ? 1 : 0} WHERE iapId='${iapId}';`);
  }

  async fetchSongs(bookId: string) {
    const result = (await this.db.query(`SELECT * FROM songs WHERE book='${bookId}';`)).values as Song[];
    // Sort in natural order by the field "number"
    result.sort((a, b) => collator.compare(a.number, b.number));
    return result;
  }

  async search(query: string) {
    if (query.trim() === '') {
      return [];
    }

    const result = (await this.db.query(`
            SELECT
                songs.id AS songId,
                songs.number AS number,
                songs.title AS title,
                books.cover AS cover,
                bm25(songSearch, 1.0, 3.0, 5.0, 1.0) AS rank
            FROM
                songSearch
            JOIN
                songs ON songs.rowid = songSearch.rowid
            JOIN
                books ON books.id = songs.book
            WHERE
                songSearch MATCH ?
            ORDER BY
                rank
            LIMIT 50;
        `, [query])).values as SearchResult[];

    return result;
  }

  async syncDatabase(): Promise<void> {
    await this.db.execute(base_schema);
    await this.db.execute(fts_schema);
    const isNetworkConnected = (await Network.getStatus()).connected;

    const databaseSyncDate = (await Preferences.get({key: "databaseSyncDate"})).value || "0";
    const currentDateTime = getCurrentDateTime();
    console.debug(`[DAO] Last database sync date: ${databaseSyncDate}`);

    if (!isNetworkConnected) {
      if (databaseSyncDate === "0") {
        // Try again after 2 seconds
        setTimeout(() => this.syncDatabase(), 2000);
      } else {
        // Don't try to sync without internet connection, just trigger reactivity
        setDatabaseTimestamp(currentDateTime);
      }
      return;
    }

    try {
      // Delete obsolete books and songs
      const bookIds = (await pb.collection('books').getFullList({fields: 'id', sort: '-sorting'})).map(book => book.id);
      const bookIdDeletions = await this.db.execute(`DELETE FROM books WHERE id NOT IN (${bookIds.map(id => `'${id}'`).join(',')})`);
      const numDeletedBooks: number = bookIdDeletions.changes?.changes || 0;

      const songIds = (await pb.collection('songs').getFullList({fields: 'id', sort: 'number'})).map(song => song.id);
      const songIdDeletions = await this.db.execute(`DELETE FROM songs WHERE id NOT IN (${songIds.map(id => `'${id}'`).join(',')})`);
      const numDeletedSongs: number = songIdDeletions.changes?.changes || 0;

      // Download new/updated books and songs
      const books = await this.getNewUpdatedRecords('books', databaseSyncDate);
      const numNewBooks = books.length;
      const songs = await this.getNewUpdatedRecords('songs', databaseSyncDate);
      const numNewSongs = songs.length;

      // Update database sync date
      await Preferences.set({key: "databaseSyncDate", value: currentDateTime});

      if (numDeletedBooks > 0 || numDeletedSongs > 0 || numNewBooks > 0 || numNewSongs > 0) {
        setDatabaseTimestamp(currentDateTime);
      }

      console.debug(`[DAO] Number of new/updated books: ${books.length}`);
      console.debug(`[DAO] Number of deleted books: ${numDeletedBooks}`);
      console.debug(`[DAO] Number of new/updated songs: ${songs.length}`);
      console.debug(`[DAO] Number of deleted songs: ${numDeletedSongs}`);
      console.debug(`[DAO] Database timestamp: ${currentDateTime}`);

    } catch (error) {
      console.error(`[DAO] Error updating the database: ${error}`);
    }
  }
}

let dao: AbstractDao;

const initDao = async () => {
  console.debug('[DAO] Initializing DAO...');
  if (dao === undefined) {
    if (Capacitor.isNativePlatform()) {
      dao = await MobileDao.create();
    } else {
      dao = new WebDao();
    }
  }
  console.debug('[DAO] DAO initialized');
  return dao;
};

export {dao, initDao, databaseTimestamp};