import {Component, Show} from "solid-js";
import {Book} from "../../data";
import {BookImage} from "../BookImage";

interface Props {
    book: Book
}
export const BookCard: Component<Props> = ({book}: Props) => {
    return (
        <div class="relative overflow-hidden rounded-lg bg-white shadow-lg transition-transform duration-300 transform active:scale-95">
            <Show when={book.iapId !== "" && !book.isPurchased}>
                <div class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 text-white bg-yellow-400 rounded-bl-lg rounded-tr-lg">
                    <svg class="h-4 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M48.1 240c-.1 2.7-.1 5.3-.1 8v16c0 2.7 0 5.3 .1 8H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H60.3C89.9 419.9 170 480 264 480h24c17.7 0 32-14.3 32-32s-14.3-32-32-32H264c-57.9 0-108.2-32.4-133.9-80H256c17.7 0 32-14.3 32-32s-14.3-32-32-32H112.2c-.1-2.6-.2-5.3-.2-8V248c0-2.7 .1-5.4 .2-8H256c17.7 0 32-14.3 32-32s-14.3-32-32-32H130.1c25.7-47.6 76-80 133.9-80h24c17.7 0 32-14.3 32-32s-14.3-32-32-32H264C170 32 89.9 92.1 60.3 176H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H48.1z"/></svg>
                </div>
            </Show>

            <Show when={book.iapId !== "" && book.isPurchased}>
                <div class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 text-white bg-green-400 rounded-bl-lg rounded-tr-lg">
                    <svg class="h-4 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                </div>
            </Show>

            <div class="flex">
                <BookImage class="w-20 h-20 bg-gray-500 shrink-0" book={book} size={160}/>
                <div class="flex flex-col px-2 my-auto">
                    <div class="mr-4 font-semibold line-clamp-1">{book.title}</div>
                    <div class="leading-tight text-sm line-clamp-3" innerHTML={book.subtitle}></div>
                </div>
            </div>
        </div>
    )
}