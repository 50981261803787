import {Filesystem, GetUriOptions} from "@capacitor/filesystem";

export function getCurrentDateTime(safe=false) {
    const date = new Date();

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');

    let result;
    if (safe) {
        result = `${year}-${month}-${day} ${hours}-${minutes}-${seconds}.${milliseconds}Z`
    } else {
        result = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}Z`
    }

    return result;
}

// export function hashString(str: string) {
//     var hash = 5381;
//     for (var i = 0; i < str.length; i++) {
//         hash = ((hash << 5) + hash) + str.charCodeAt(i); /* hash * 33 + c */
//     }
//     return (hash & 0xFFFFFFFF).toString(16);
// }

// See https://github.com/mstdokumaci/string-hash-64/blob/master/index.js
export function hashString(str: string) {
    let i = str.length
    let hash1 = 5381
    let hash2 = 52711

    while (i--) {
        const char = str.charCodeAt(i)
        hash1 = (hash1 * 33) ^ char
        hash2 = (hash2 * 33) ^ char
    }

    return ((hash1 >>> 0) * 4096 + (hash2 >>> 0)).toString(16);
}

export async function getUriIfFileExists(uriOptions: GetUriOptions): Promise<string | null> {
    try {
        const stat = await Filesystem.stat(uriOptions);
        return stat.uri;
    } catch (error) {
        return null;
    }
}

export function map(x: number, in_min: number, in_max: number, out_min: number, out_max: number): number {
    return (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

export function cumulativeSum(arr: number[]): number[] {
    return arr.reduce((resultArray: number[], num: number, index: number) => {
        const lastNumber = index > 0 ? resultArray[index - 1] : 0;
        resultArray.push(lastNumber + num);

        return resultArray;
    }, []);
}

export function binarySearch(arr: number[], target: number): number {
    let left = 0;
    let right = arr.length - 1;

    while (left <= right) {
        const mid = Math.floor((left + right) / 2);

        if (arr[mid] === target) {
            return mid;
        }

        if (arr[mid] < target) {
            left = mid + 1;
        } else {
            right = mid - 1;
        }
    }

    // return the index of the next smaller number
    return left;
}

export function removePrefix(s: string): string {
    return s.replace(/^\d+\w*\.(\(\w*\.?\))?/, '');
}

export function replaceLast(original: string, search: string, replacement: string): string {
    const pos = original.lastIndexOf(search);
    if (pos !== -1) {
        return original.substring(0, pos) + replacement + original.substring(pos + search.length);
    }
    return original;
}

export function splitAtLast(input: string, char: string): string[] {
    const lastCharIndex: number = input.lastIndexOf(char);

    if (lastCharIndex === -1) {
        return [input];
    }

    const firstPart: string = input.slice(0, lastCharIndex);
    const secondPart: string = input.slice(lastCharIndex + 1);

    return [firstPart, secondPart];
}

export class Mutex {
    private _queue: Promise<void>;

    constructor() {
        this._queue = Promise.resolve();
    }

    async lock() {
        let releaseLock: () => void;
        const waitUntilFree = new Promise<void>(resolve => releaseLock = resolve);
        const lock = this._queue.then(() => releaseLock);
        this._queue = this._queue.then(() => waitUntilFree);
        return lock;
    }
}


export function debounce<F extends (...args: any[]) => any>(func: F, waitFor: number) {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;

    return (...args: Parameters<F>): Promise<ReturnType<F>> => new Promise((resolve) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => resolve(func(...args)), waitFor);
    });
}