export const parser = new DOMParser();

export const serializer = new XMLSerializer();

const nsResolver = (prefix: string | null) => {
    const namespaces: Record<string, string> = {
        mei: "http://www.music-encoding.org/ns/mei"
    };
    return namespaces[prefix ?? ""] ?? null;
};

export function select(xpath: string, root: Node) {
    const doc = root.ownerDocument || (root.getRootNode() as Document);
    const result = doc.evaluate(xpath, root, nsResolver, XPathResult.UNORDERED_NODE_SNAPSHOT_TYPE, null);
    const nodes: Node[] = [];
    for (let i = 0; i < result.snapshotLength; i++) {
        nodes.push(result.snapshotItem(i)!);
    }
    return nodes
}