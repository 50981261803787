/* @refresh reload */
import {render} from 'solid-js/web';
import {hashIntegration, Router} from "@solidjs/router";
import {CapacitorUpdater} from '@capgo/capacitor-updater'
import * as Sentry from "@sentry/capacitor";

import './app.css';
import App from './App';

import {initPlatform} from './utils/platform';
import {dao, initDao} from "./data";
import {worker} from "./score";


const root = document.getElementById('root');

if (import.meta.env.DEV && !(root instanceof HTMLElement)) {
    throw new Error(
        'Root element not found. Did you forget to add it to your index.html? Or maybe the id attribute got misspelled?',
    );
}

// Disable double tap everywhere
// document.ondblclick = function (e) {
//     e.preventDefault();
// }

// Sentry.init({
//     dsn: import.meta.env.VITE_GLITCHTIP_DSN,
// });

Sentry.init({
    dsn: "https://09c857eb81adc906c5c716a52d275856@o4505789583917056.ingest.sentry.io/4505789588570112",
    // Set your release version, such as 'getsentry@1.0.0'
    release: "cantico@2.0.0",
    // Set your dist version, such as "1"
    dist: "2",
});


// const bundles = await CapacitorUpdater.list();
// console.log("Bundles:", bundles);
//
// // Update app
// let bundleInfo: BundleInfo;
// CapacitorApp.addListener('appStateChange', async (state) => {
//     if (state.isActive) {
//         // Ensure download occurs while the app is active, or download may fail
//         bundleInfo = await CapacitorUpdater.download({
//             url: 'https://pb.cantico-app.de/api/files/5mdqza20oznluva/8o05li8va1vnl45/index_html_P7ANzbAk2m.zip',
//             version: "2.0.2"
//         })
//
//         // Copy verovio to new bundle
//         const response = await axios.get("/externals/verovio-toolkit-wasm.js", {
//             responseType: 'blob'
//         });
//         await write_blob({
//             path: 'versions/' + bundleInfo.id + '/externals/verovio-toolkit-wasm.js',
//             directory: Directory.Documents,
//             blob: response.data,
//             recursive: true
//         })
//     }
//
//     if (!state.isActive && bundleInfo) {
//         // Activate the update when the application is sent to background
//         // SplashScreen.show()
//         try {
//             await CapacitorUpdater.set(bundleInfo);
//             // At this point, the new version should be active, and will need to hide the splash screen
//         } catch {
//             // SplashScreen.hide() // Hide the splash screen again if something went wrong
//         }
//     }
// })

// Init data
await initDao();
dao.syncDatabase().then(async () => {
    await initPlatform();
    worker.postMessage({cmd: "init"})
})

CapacitorUpdater.notifyAppReady();

render(
    () => (
        <Router source={hashIntegration()}>
            <App/>
        </Router>
    ),
    root!
);
