import {debounce} from "../utils/misc";

export enum MODE {
    DEFAULT = "DEFAULT",
    INSTRUMENTAL = "INSTRUMENTAL",
}

export class AudioPlayer {
    private _silenceAudio: HTMLAudioElement;
    private _audioContext: AudioContext;
    private _defaultTrackBuffers?: AudioBuffer[];
    private _defaultDurations: number[] = [];
    private _instrumentalTrackBuffers?: AudioBuffer[];
    private _instrumentalDurations: number[] = [];
    private _currentTrackIndex: number = 0;
    private _currentTrackTime: number = 0;
    private _currentTrackStartTime: number = 0;
    private _currentMode: MODE;
    private _playingSources: AudioBufferSourceNode[] = [];
    private _isPlaying: boolean = false;
    private _hasSeeked: boolean = false;
    readonly canPlayOggOpus: boolean;
    private _crossfadeDuration: number = 0.2; // Crossfade duration in seconds

    constructor(mode: MODE = MODE.DEFAULT) {
        this._silenceAudio = new Audio('/media/silence.mp3');
        this.canPlayOggOpus = this._silenceAudio.canPlayType('audio/ogg; codecs=opus') !== '';

        this._currentMode = mode;
        this._audioContext = new AudioContext();
    }

    private loadTrackToAudioBuffer = async (trackUrl: string): Promise<AudioBuffer> => {
        try {
            const response = await fetch(trackUrl);
            const arrayBuffer = await response.arrayBuffer();
            return await this._audioContext.decodeAudioData(arrayBuffer);
        } catch (err) {
            console.error(`Error in loadTrackToAudioBuffer for file "${trackUrl}": `, err);
            throw err;
        }
    }

    loadPlaylist = debounce((defaultTrackUrls: string[], instrumentalTrackUrls?: string[]) => this._loadPlaylist(defaultTrackUrls, instrumentalTrackUrls), 500);
    private async _loadPlaylist(defaultTrackUrls: string[], instrumentalTrackUrls?: string[]) {
        // Cleaning up old buffers
        this._defaultTrackBuffers = undefined;
        this._instrumentalTrackBuffers = undefined;
        this._defaultDurations = [];
        this._instrumentalDurations = [];

        const defaultTrackPromises: Promise<AudioBuffer>[] = defaultTrackUrls.map(this.loadTrackToAudioBuffer);

        let instrumentalTrackPromises: Promise<AudioBuffer>[] = [];
        if (instrumentalTrackUrls) {
            instrumentalTrackPromises = instrumentalTrackUrls.map(this.loadTrackToAudioBuffer);
        }

        const loadAllPlaylists = Promise.all([
            Promise.all(defaultTrackPromises),
            Promise.all(instrumentalTrackPromises)
        ]);

        const [defaultBuffers, instrumentalBuffers] = await loadAllPlaylists;

        this._defaultTrackBuffers = defaultBuffers;
        if (instrumentalTrackUrls) {
            this._instrumentalTrackBuffers = instrumentalBuffers;
        }

        this._defaultDurations = defaultBuffers.map(buffer => buffer.duration)
        this._instrumentalDurations = instrumentalBuffers.map(buffer => buffer.duration)
    }

    private clearPlayingSources(): void {
        this._playingSources.forEach(source => {
            source.disconnect();
            source.onended = null;
        });
        this._playingSources = [];
    }

    private createSource(trackBuffer: AudioBuffer): AudioBufferSourceNode {
        const source = this._audioContext.createBufferSource();
        source.buffer = trackBuffer;
        source.connect(this._audioContext.destination);
        return source;
    }

    private getCurrentTrackBuffers(): AudioBuffer[] | undefined {
        return this._currentMode === MODE.DEFAULT ? this._defaultTrackBuffers : this._instrumentalTrackBuffers;
    }

    private schedulePlay(trackIdx: number, timeInTrack: number = 0): void {
        const trackBuffers = this.getCurrentTrackBuffers();

        if (!trackBuffers || trackIdx < 0 || trackIdx >= trackBuffers.length) {
            console.error('Invalid track index');
            return;
        }

        this._currentTrackIndex = trackIdx;
        this._currentTrackTime = timeInTrack;
        this._currentTrackStartTime = this._audioContext.currentTime - timeInTrack;

        let startAt = this._audioContext.currentTime;

        for (let i = this._currentTrackIndex; i < trackBuffers.length; i++) {
            const trackBuffer = trackBuffers[i];
            const source = this.createSource(trackBuffer);

            source.onended = () => {
                this._currentTrackIndex = i + 1;
                if (this._currentTrackIndex >= trackBuffers.length) {
                    this._currentTrackIndex = 0;
                    this._currentTrackTime = 0;
                    this.clearPlayingSources();
                    this._isPlaying = false;
                } else {
                    this._currentTrackStartTime = this._audioContext.currentTime;
                }
            }

            if (i === this._currentTrackIndex) {
                source.start(startAt, timeInTrack);
                startAt += trackBuffer.duration - timeInTrack;
            } else {
                source.start(startAt);
                startAt += trackBuffer.duration;
            }

            this._playingSources.push(source);
        }
    }

    play(): void {
        const trackBuffers = this.getCurrentTrackBuffers();

        if (!trackBuffers) {
            console.error('No playlist loaded');
            return;
        }

        this._silenceAudio.play().then(() => {
            this.clearPlayingSources();
            this.schedulePlay(this._currentTrackIndex, this._currentTrackTime);
            this._isPlaying = true;
            this._hasSeeked = false;
        })
    }

    pause(): void {
        if (this._isPlaying) {
            this._currentTrackTime = this._audioContext.currentTime - this._currentTrackStartTime;
            this.clearPlayingSources();
            this._isPlaying = false;
        }
    }

    seek(trackIdx: number, timeWithinTrack: number = 0): void {
        const trackBuffers = this.getCurrentTrackBuffers();

        if (!trackBuffers || trackIdx < 0 || trackIdx >= trackBuffers.length) {
            console.error('Invalid track index');
            return;
        }

        this._currentTrackIndex = trackIdx;
        this._currentTrackTime = timeWithinTrack;

        if (this._isPlaying) {
            this.clearPlayingSources();
            this.schedulePlay(this._currentTrackIndex, this._currentTrackTime);
        }

        this._hasSeeked = true;
    }

    getCurrentTrack(): number {
        if (!this.getCurrentTrackBuffers()) {
            // console.error('No playlist loaded');
            return 0;
        }

        return this._currentTrackIndex;
    }

    getCurrentTime(): number {
        if (!this.getCurrentTrackBuffers()) {
            // console.error('No playlist loaded');
            return 0;
        }

        if (this._isPlaying) {
            this._currentTrackTime = this._audioContext.currentTime - this._currentTrackStartTime;
        }

        return this._currentTrackTime;
    }

    setMode(mode: MODE): void {
        if (mode === MODE.INSTRUMENTAL && !this._instrumentalTrackBuffers) {
            console.warn('Instrumental mode cannot be set as instrumental playlist is not loaded');
            return;
        }

        this._currentMode = mode;
        this.seek(this.getCurrentTrack(), this.getCurrentTime())
    }

    getMode(): MODE {
        return this._currentMode;
    }

    isPlaying(): boolean {
        return this._isPlaying;
    }

    getDurations() {
        return this._currentMode === MODE.DEFAULT ? this._defaultDurations :  this._instrumentalDurations;
    }
}