import {Component, Show} from "solid-js";
import {Song} from "../../data";

interface Props {
    style?: string | {};
    class?: string;
    song: Song;
    downloadProgress?: number;
    isBookOwned: boolean;
}

const SongListItem: Component<Props> = (props: Props) => {
    return (
        <div class={`cursor-pointer relative px-3 py-2 flex flex-row leading-tight ${props.class}`}
             style={props.style}
        >
            <div class="z-10">{props.song.number}.&nbsp;</div>
            <div class="z-10 grow">{props.song.title}</div>
            <div class="z-10 my-auto">
                <Show when={props.song.isDownloaded || (props.downloadProgress && props.downloadProgress >= 1.0)}>
                    <svg class="h-5 pl-2 fill-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z" /></svg>
                </Show>
            </div>

            <Show when={props.isBookOwned}>
                <div class="z-10 my-auto">
                    <svg class="h-4 pl-2 fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
                </div>
            </Show>

            <Show when={!props.song.isDownloaded && (props.downloadProgress && props.downloadProgress < 1.0)}>
                <div class="absolute top-0 left-0 h-full bg-gradient-to-r from-transparent to-green-200 transition-all duration-100" style={`width: ${(props.downloadProgress || 0) * 100}%`}></div>
            </Show>
        </div>
    );
}
export default SongListItem;