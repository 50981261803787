import {SongPart, SongJson} from "./types";
import {replaceLast} from "../utils/misc";
import {parser, select, serializer} from "../utils/mei";

interface Playlist {
    vocal: string[],
    instrumental: string[],
}

function meiToText(part: SongPart): string {
    if (!part.score || part.lyrics == "") {
        return "";
    }

    let textHtml = '';

    const isNewPart = part.label.toLowerCase().indexOf("klammer") === -1;

    let meiXml = parser.parseFromString(`<root>${part.score}</root>`, "text/xml");
    let staff = '1' // TODO have dynamic check from MEI content
    let syls = meiXml.querySelectorAll(`staff[n="${staff}"] syl`);

    // TODO move to DB population script (actually most parts already there)
    if(syls.length > 0) {
        if (isNewPart) {
            textHtml += `<div class="part-header">${part.label}</div><div class="part-content">`;
        }

        if(syls[0].textContent){
            syls[0].textContent = syls[0].textContent.replace(/^\d+\./, '').trimStart();
        }

        syls.forEach(function (syl) {
            let wordHasMultipleSyllables = false;
            let wordpos = syl.getAttribute("wordpos");

            if (wordpos === "i" || wordpos === "m") {
                wordHasMultipleSyllables = true;
            }

            let newId = "text-" + syl.getAttribute("xml:id");
            textHtml += `<span id="${newId}">${syl.textContent}</span>`;

            if (!wordHasMultipleSyllables) {
                textHtml += " ";
            }
        });

        if (isNewPart) {
            textHtml += `</div>`;
        }
    }

    return textHtml;
}

export async function loadSongJson(json: SongJson, selectedParts: number[]) {
    // console.log(json)
    const parts: SongPart[] = selectedParts.map(idx => json.parts[idx]);

    let playlists: Playlist = {vocal: [], instrumental: []};
    let trackNames: string[] = [];
    let score = "";
    let textHtml = "";

    const handleAudio = (audioType: {
        default: string[],
        ending?: string[]
    }, playlistType: 'vocal' | 'instrumental', isLast: boolean) => {
        let audio = (audioType[isLast ? 'ending' : 'default'] ?? audioType.default);
        // audio = audio.map(a => a.toLowerCase().replace(/\.$/, "").replace(/[^a-z0-9_]/gi, "_"));
        playlists[playlistType].push(...audio);
    }

    for (let i = 0; i < parts.length; i++) {
        const part = parts[i];
        const isLast = i === parts.length - 1;
        const audio = part.audio;

        if (audio) {
            handleAudio(audio.vocal ?? audio.instrumental, 'vocal', isLast);
            handleAudio(audio.instrumental, 'instrumental', isLast);
            trackNames.push(part.label);
        }

        if (part.score) {
            score += part.score;
        }
        textHtml += meiToText(part);
    }

    score = replaceLast(score, 'right="dbl"', 'right="end"');
    score = `<music><body><mdiv><score>${score}<\/score><\/mdiv><\/body><\/music>`;
    score = fixHarms(score); // TODO move to DB population script

    // Enumerate measures
    let meiXml = parser.parseFromString(score, "text/xml");
    let measures = meiXml.querySelectorAll('measure');
    measures.forEach(function (measure, i) {
        measure.setAttribute('n', (i + 1).toString());
    })
    score = serializer.serializeToString(meiXml);


    return {
        playlist: playlists,
        trackNames,
        score,
        textHtml
    };

}

function fixHarms(meiString: string): string {
    function splitSpecialParts(text: string): Array<[string, boolean]> {
        const specialRanges = [
            [0x1D100, 0x1D1FF],  // Musical symbols
            [0x2600, 0x26FF],  // Misc symbols
        ];

        const parts: Array<[string, boolean]> = [];
        let curPart = "";
        let curPartHasSpecial = false;

        for (const char of text) {
            const codePoint = char.codePointAt(0) || 0;
            let isSpecial = false;

            for (const [start, end] of specialRanges) {
                if (start <= codePoint && codePoint <= end) {
                    isSpecial = true;
                    break;
                }
            }

            if (isSpecial !== curPartHasSpecial) {
                parts.push([curPart, curPartHasSpecial]);
                curPart = "";
                curPartHasSpecial = isSpecial;
            }

            curPart += char;
        }

        parts.push([curPart, curPartHasSpecial]);
        return parts;
    }

    const ns = {
        mei: "http://www.music-encoding.org/ns/mei",
        xml: "http://www.w3.org/XML/1998/namespace"
    };

    const mei = parser.parseFromString(meiString, "text/xml");

    for (const harm of select("//mei:harm", mei)) {
        let text = "";
        for (const node of select(".//text()", harm)) {
            text += node.textContent?.trim() || "";
        }

        while (harm.firstChild) {
            harm.removeChild(harm.firstChild);
        }

        harm.textContent = "";
        let curElem = harm;
        const parts = splitSpecialParts(text);

        for (const [part, isSpecial] of parts) {
            if (isSpecial) {
                const sub = mei.createElementNS(ns.mei, "rend");
                sub.setAttribute("fontsize", "85%");
                sub.setAttribute("fontfam", "smufl");
                sub.textContent = part;
                curElem = sub;
                harm.appendChild(sub);
            } else {
                const textNode = mei.createTextNode(part);
                if (curElem === harm) {
                    harm.appendChild(textNode);
                } else {
                    harm.appendChild(textNode);
                    curElem = textNode;
                }
            }
        }
    }

    return serializer.serializeToString(mei);
}