import {Component, onMount} from 'solid-js';
import {Route, Routes, useLocation} from "@solidjs/router";

import Home from './components/home';
import Settings from './components/settings';
import Book from './components/book';
import Song from './components/song';

import {setupBackSwipeNavigationIOS} from "./utils/platform";
import {StatusBar} from "@capacitor/status-bar";
import Favorites from "./components/favorites";

const App: Component = () => {
    const location = useLocation();
    const mainPages = ['/', '/home', '/favorites', '/settings', '/collection/'];

    onMount(() => {
        setupBackSwipeNavigationIOS(() => {
            /* right swipe */
            if (!mainPages.includes(location.pathname)) {
                StatusBar.show();
                history.back();
            }
        });
    });

    return (
        <Routes>
            <Route path="/" component={Home}/>
            <Route path="/favorites" component={Favorites}/>
            <Route path="/settings" component={Settings}/>
            <Route path="/collection/:id" component={Book}/>
            <Route path="/song/:id" component={Song}/>
        </Routes>
    );
};

export default App;
