import {A} from "@solidjs/router";
import {Component, createEffect, createSignal, For, onMount, Show} from "solid-js";
import {Book, dao, databaseTimestamp} from "../../data";
import {BookCard} from "./BookCard";
import BottomNavigation from "../BottomNavigation";
import {BookImage} from "../BookImage";
import {lastApprovedId} from "../../iap";
import SearchResults from "../SearchResults";
import {Capacitor} from "@capacitor/core";


const Home: Component = () => {
  const [isScrolled, setIsScrolled] = createSignal(false);
  const [showSyncing, setShowSyncing] = createSignal(false);
  const [books, setBooks] = createSignal<Book[]>([]);
  const [query, setQuery] = createSignal<string>("");


  let timeoutId: number;

  const newestBook = (): Book => {
    return books().filter(b => b.isVisible && b.status === "active").sort((a, b) => a.sorting < b.sorting ? 1 : -1)[0];
  }

  // const recentBooks = (number?: number) => {
  //     const opened = books().filter(book => book.lastOpened != '').sort((a, b) => a.lastOpened > b.lastOpened ? 1 : -1);
  //
  //     if (number) {
  //         return opened.slice(0, number);
  //     } else {
  //         return opened
  //     }
  // };

  createEffect(async () => {
    if (databaseTimestamp()) setBooks(await dao.fetchBooks());
  });

  createEffect(async () => {
    if (lastApprovedId()) {
      setBooks(await dao.fetchBooks());
    }
  })

  createEffect(() => {
    if (books().length > 0 && timeoutId) {
      clearTimeout(timeoutId);
      setShowSyncing(false);
    }
  })

  let mainRef: HTMLDivElement;

  onMount(async () => {
    timeoutId = window.setTimeout(() => setShowSyncing(true), 500);
    setBooks(await dao.fetchBooks());
  })

  const onScroll = (event: Event) => {
    setIsScrolled(mainRef.scrollTop > 0);
  }

  return (
    <div class="flex flex-col h-full bg-cantico-50 overflow-hidden">
      {/*Top bar*/}
      <div
        class={`${isScrolled() ? "shadow-lg" : "shadow-none"} transition-shadow duration-300 z-10 shrink-0 flex items-center w-full py-2 px-4 safe-top`}>
        <div
          class="relative rounded-lg shadow-md bg-white h-10 w-10 shrink-0 flex items-center justify-center overflow-hidden">
          <img src="cantico.svg"/>
        </div>
        <div class="ml-2 relative rounded-lg shadow-md w-full grow">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <svg class="fill-gray-400 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
            </svg>
          </div>
          <input type="text"
                 class="focus:outline-0 focus:ring-0 block w-full rounded-lg border-0 py-2 pl-10 bg-white placeholder:text-gray-300"
                 placeholder="Suchen..." onInput={(e) => setQuery(e.target.value)}/>
        </div>
        {/*<button class="ml-2 relative rounded-lg shadow-md bg-white h-10 w-10 shrink-0 flex items-center justify-center">*/}
        {/*    <svg class="h-5 fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 416c0 17.7 14.3 32 32 32l54.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 448c17.7 0 32-14.3 32-32s-14.3-32-32-32l-246.7 0c-12.3-28.3-40.5-48-73.3-48s-61 19.7-73.3 48L32 384c-17.7 0-32 14.3-32 32zm128 0a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM320 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm32-80c-32.8 0-61 19.7-73.3 48L32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l246.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48l54.7 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-54.7 0c-12.3-28.3-40.5-48-73.3-48zM192 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm73.3-64C253 35.7 224.8 16 192 16s-61 19.7-73.3 48L32 64C14.3 64 0 78.3 0 96s14.3 32 32 32l86.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 128c17.7 0 32-14.3 32-32s-14.3-32-32-32L265.3 64z" /></svg>*/}
        {/*</button>*/}
      </div>

      {/*Main*/}
      <div ref={mainRef!} onScroll={onScroll} class="grow overflow-y-auto px-4 pt-2 pb-4">
        <Show when={query() == ""}>
          <Show when={books().length > 0}>
            <Show when={!newestBook().isPurchased}>
              <div class="relative mb-5">
                <div class="font-semibold tracking-wide text-gray-400 uppercase mb-1">Neuerscheinung</div>
                <A href={`/collection/${newestBook().id}`}
                   class="flex items-center w-full h-40 rounded-lg shadow-xl bg-gradient-to-r from-cantico-900 from-10% via-cantico-700 via-70% to-cantico-950">
                  <div class="px-4 text-center text-white font-bold text-lg md:text-2xl lg:text-3xl grow">
                    {newestBook().title}
                  </div>
                  <div class="px-4 -mt-8 shrink-0">
                    <BookImage class="animate-bounce-slow h-40 rounded-lg shadow-lg"
                               book={newestBook()}
                               size={320}
                    />
                  </div>
                </A>
              </div>
            </Show>

            {/*<Show when={recentBooks().length > 0}>*/}
            {/*    <div class="font-semibold tracking-wide text-gray-400 uppercase">Zuletzt geöffnet</div>*/}
            {/*    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-3 gap-y-2 mb-3">*/}
            {/*        <For each={recentBooks(3)}>{(book) =>*/}
            {/*            <A href={`/collection/${book.id}`}>*/}
            {/*                <BookCard book={book}/>*/}
            {/*            </A>*/}
            {/*        }</For>*/}
            {/*    </div>*/}
            {/*</Show>*/}

            <div class="font-semibold tracking-wide text-gray-400 uppercase mb-1">Alle Sammlungen</div>
            <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-3 gap-y-2">
              <For
                // each={books!().filter((book: Book) => (book.status === 'active' && (book.isVisible || book.isPurchased)))}>{(book: Book) => ( // TODO handle "active", "visible" etc.
                each={books!()}>{(book: Book) => (
                <A href={`/collection/${book.id}`}>
                  <BookCard book={book}/>
                </A>
              )}</For>
            </div>

          </Show>

          <Show when={showSyncing()}>
            <div class="pt-12 flex flex-col items-center justify-center w-full h-full text-gray-400 text-lg">
              <svg class="h-32 w-32 fill-gray-300 animate-spin" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 512 512">
                <path
                  d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"/>
              </svg>
              <div class="mt-8 px-12 max-w-xl text-center">Datenbank wird synchronisiert. Stellen Sie bitte
                sicher, dass Sie eine Verbindung zum Internet haben.
              </div>
            </div>
          </Show>
        </Show>

        {/*Search results*/}
        <Show when={query() != ""}>
          <SearchResults query={query()}/>
        </Show>
      </div>

      {/*Bottom bar*/}
      <Show when={!showSyncing() && Capacitor.isNativePlatform()}>
        <div class="shrink-0">
          <BottomNavigation/>
        </div>
      </Show>

    </div>
  );
}

export default Home;