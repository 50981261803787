import {Component, createEffect, createResource, createSignal, Show, For, onMount, onCleanup} from 'solid-js';
import {isFullscreen, setIsFullscreen} from "../../utils/platform";
import {SongJson, dao, getUriFromCacheOrDownload, SongPart} from "../../data";
import {useParams} from "@solidjs/router";
import {removePrefix} from "../../utils/misc";
import {AudioPlayer, MODE} from "../../audio";
import {SegmentedAudioSlider} from "./SegmentedAudioSlider";
import {Capacitor} from "@capacitor/core";
import {loadSongJson} from "../../data/bundle";
import {MediaSession} from "@jofr/capacitor-media-session";
import * as Sentry from "@sentry/capacitor";
import {ScoreViewer} from "./ScoreViewer";


export const Song: Component = () => {
    const params = useParams()

    const [isSheetVisible, setIsSheetVisible] = createSignal(false);
    const [isScoreVisible, setIsScoreVisible] = createSignal(true);
    const [isWithSinging, setIsWithSinging] = createSignal(true);
    const [isFavorit, setIsFavorit] = createSignal(false);
    const [hasShadow, setHasShadow] = createSignal(false);

    const [song] = createResource(params.id, dao.fetchSong.bind(dao));

    const [parts, setParts] = createSignal<number[]>([]);
    const [songData, setSongData] = createSignal<SongJson>();

    const [trackNames, setTrackNames] = createSignal<string[]>([]);
    const [durations, setDurations] = createSignal<number[]>([])
    const [track, setTrack] = createSignal(0);
    const [time, setTime] = createSignal(0);
    const [isPlaying, setIsPlaying] = createSignal(false);

    const [isPlaylistLoaded, setIsPlaylistLoaded] = createSignal(false);

    const [renderMode, setRenderMode] = createSignal<"score" | "text">("score")
    const [scoreData, setScoreData] = createSignal('');
    const [textData, setTextData] = createSignal('');

    const [hasAudio, setHasAudio] = createSignal(true);

    let overlayRef: HTMLDivElement;
    let scoreContainerRef: HTMLDivElement;
    let textContainerRef: HTMLDivElement;

    const audioPlayer = new AudioPlayer();
    let checkboxes: HTMLInputElement[] = [];

    onMount(() => {

    })

    onCleanup(async () => {
        audioPlayer.pause();
        await MediaSession.setPlaybackState({playbackState: "none"});
    })

    const checkShadow = () => {
        setHasShadow((overlayRef.scrollHeight > overlayRef.clientHeight) && (overlayRef.scrollTop + overlayRef.clientHeight < overlayRef.scrollHeight));
    }

    const seek = (tr: number, ti: number): void => {
        setTrack(tr);
        setTime(ti);
        audioPlayer.seek(tr, ti)
    }

    const playPause = async () => {
        if (isPlaylistLoaded()) {
            if (audioPlayer.isPlaying()) {
                audioPlayer.pause();
                await MediaSession.setPlaybackState({playbackState: "paused"});
            } else {
                audioPlayer.play();
                await MediaSession.setPlaybackState({playbackState: "playing"});
            }
        }
    }

    const toggleMode = () => {
        setIsWithSinging(!isWithSinging());
        if (isWithSinging()) {
            audioPlayer.setMode(MODE.DEFAULT);
        } else {
            audioPlayer.setMode(MODE.INSTRUMENTAL);
        }
    }

    const onSelectPart = () => {
        const newParts = checkboxes
            .map((checkbox, index) => checkbox.checked ? index : -1)
            .filter(index => index !== -1);
        setParts(newParts);
    }

    createEffect(async () => {
        if (song()) {
            const book = await dao.fetchBook(song()!.book)
            setHasAudio(book.hasMusic);
            Sentry.setContext("song", {
                songId: song()!.id,
                bookId: book.id,
                songTitle: `${song()!.number}. ${song()!.title}`,
                bookTitle: book.title,
            })


            const data: SongJson = await dao.fetchSongData(song()!.id);
            setSongData(data);
            setParts((new Array(data.parts.length)).fill(null).map((_, i) => i));
            setIsSheetVisible(true);
            checkShadow();


            await MediaSession.setMetadata({
                title: song()!.title,
                artist: book.title,
                artwork: []
                // artwork: [
                //     {
                //         src: "../assets/logo/logo_96x96.png",
                //         sizes: "96x96",
                //         type: "image/png",
                //     },
                //     {
                //         src: "../assets/logo/logo_128x128.png",
                //         sizes: "128x128",
                //         type: "image/png",
                //     },
                //     {
                //         src: "../assets/logo/logo_192x192.png",
                //         sizes: "192x192",
                //         type: "image/png",
                //     },
                //     {
                //         src: "../assets/logo/logo_256x256.png",
                //         sizes: "256x256",
                //         type: "image/png",
                //     },
                //     {
                //         src: "../assets/logo/logo_384x384.png",
                //         sizes: "384x384",
                //         type: "image/png",
                //     },
                //     {
                //         src: "../assets/logo/logo_512x512.png",
                //         sizes: "512x512",
                //         type: "image/png",
                //     },
                // ]
            })
        }
    })

    createEffect(async () => {
        if (parts().length > 0) {
            setIsPlaylistLoaded(false);
            audioPlayer.pause();
            const data = await loadSongJson(songData()!, parts());
            const playlist = data.playlist;
            const musicFileDict = await dao.fetchSongMusicUrls(song()!.id);

            // TODO move to dao or somewhere else?
            let vocalPlaylist: string[];
            let instrumentalPlaylist: string[];
            if (Capacitor.isNativePlatform()) {
                vocalPlaylist = await Promise.all(playlist.vocal.map(name => musicFileDict[name]).map(async url => Capacitor.convertFileSrc(await getUriFromCacheOrDownload(url))))
                instrumentalPlaylist = await Promise.all(playlist.instrumental.map(name => musicFileDict[name]).map(async url => Capacitor.convertFileSrc(await getUriFromCacheOrDownload(url))))
            } else {
                vocalPlaylist = playlist.vocal.map(name => musicFileDict[name]).map(url => Capacitor.convertFileSrc(url))
                instrumentalPlaylist = playlist.instrumental.map(name => musicFileDict[name]).map(url => Capacitor.convertFileSrc(url))
            }
            setHasAudio(vocalPlaylist.length > 0);

            // console.log('vocal', vocalPlaylist);
            // console.log('instrumental', instrumentalPlaylist);

            setTrackNames(data.trackNames);
            audioPlayer.loadPlaylist(vocalPlaylist, instrumentalPlaylist)
                .then(async () => {
                    audioPlayer.seek(0, 0)
                    setIsPlaylistLoaded(true);
                    setDurations(audioPlayer.getDurations());

                    setInterval(() => {
                        setTrack(audioPlayer.getCurrentTrack());
                        setTime(audioPlayer.getCurrentTime());

                        setIsPlaying(audioPlayer.isPlaying());
                    }, 50);

                    await MediaSession.setActionHandler({action: "play"}, () => {
                        audioPlayer.play();
                        MediaSession.setPlaybackState({playbackState: "playing"});
                    });
                    await MediaSession.setActionHandler({action: "pause"}, () => {
                        audioPlayer.pause()
                        MediaSession.setPlaybackState({playbackState: "paused"});
                    });
                })

            setScoreData(data.score);
            setTextData(data.textHtml);
        }
    })


    return (
        <div class="flex flex-col h-full bg-cantico-50 overflow-hidden">
            <div
                class={`${isSheetVisible() ? "bg-black/50" : "bg-transparent pointer-events-none"} z-10 duration-300 transform transition-all w-full h-full absolute top-0 left-0 `}
                onClick={() => setIsSheetVisible(false)}/>

            {/*Top bar*/}
            <Show when={!isFullscreen()}>
                <div class={`shrink-0 flex items-center w-full py-2 px-4 safe-top`}>
                    <button
                        class="relative rounded-lg shadow-md bg-white h-10 w-10 shrink-0 flex items-center justify-center"
                        onClick={() => history.back()}>
                        <svg class="h-5 fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                            <path
                                d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/>
                        </svg>
                    </button>
                    <div class="ml-2 w-full grow line-clamp-1">
                        {song()?.title}
                    </div>
                </div>
            </Show>

            {/*Main*/}
            <div class="relative grow h-full bg-white overflow-hidden">

                <Show when={renderMode() === 'score' && scoreData() !== ''}>
                    <ScoreViewer class="h-full w-full" ref={scoreContainerRef!} mei={scoreData()}/>
                </Show>
                <Show when={renderMode() === 'text'}>
                    <div ref={textContainerRef!} class="h-full w-full px-4 pb-4 overflow-y-auto max-w-2xl mx-auto" innerHTML={textData()}>Text</div>
                </Show>


                {/*Overlay*/}
                <div
                    class={`${isSheetVisible() ? "bottom-0 " : "-bottom-full"} z-20 transform transition-all duration-300 rounded-t-3xl absolute bg-cantico-50 left-0 w-full px-4 pt-4 pb-2 overflow-y-scroll no-scrollbar`}
                    style={{
                        "max-height": "80%",
                        // "transform": "translate(-50%, 0)",
                        // "left": "50%",
                }}
                    onScroll={checkShadow}
                    ref={overlayRef!}
                >
                    <div class="w-full flex justify-end">
                        <button onClick={() => setIsSheetVisible(false)}>

                          <svg class="h-7 fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM186.3 136.5L256 218.8l69.7-82.3c8.6-10.1 23.7-11.4 33.8-2.8s11.4 23.7 2.8 33.8L287.4 256l74.9 88.5c8.6 10.1 7.3 25.3-2.8 33.8s-25.3 7.3-33.8-2.8L256 293.2l-69.7 82.3c-8.6 10.1-23.7 11.4-33.8 2.8s-11.4-23.7-2.8-33.8L224.6 256l-74.9-88.5c-8.6-10.1-7.3-25.3 2.8-33.8s25.3-7.3 33.8 2.8z"/></svg>
                        </button>
                    </div>
                    <div class="font-semibold text-lg">{song()?.title}</div>
                    <div class="" innerHTML={song()?.legal}/>

                    <div class="font-semibold tracking-wide text-gray-400  uppercase mt-4 mb-2">Liedteile</div>

                    <For each={songData()?.parts}>{(part, i) => (
                        <label class="inline-flex items-center w-full">
                            <input type="checkbox" checked={parts().includes(i())} onClick={onSelectPart}
                                   ref={el => checkboxes[i()] = el}
                                   class="w-6 h-6 border-2 border-gray-300 rounded text-cantico-800 focus:ring-0 focus:ring-offset-0"/>
                            <span class={`${!part.lyrics && "italic"} w-full ml-2 text-gray-500 line-clamp-1`}>
                                <span class="text-gray-900">{part.label.trim()}</span>
                                <Show when={part.lyrics}>
                                    <span class="text-sm ml-1"> {removePrefix(part.lyrics!)}</span>
                                </Show>
                            </span>
                        </label>
                    )}</For>

                    {/*<button*/}
                    {/*    class="mt-4 bg-white flex items-center justify-center w-full px-2 py-2 font-semibold border-2 border-gray-300 rounded-lg text-cantico-800">*/}
                    {/*    <svg class="h-6 fill-cantico-800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">*/}
                    {/*        <path*/}
                    {/*            d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/>*/}
                    {/*    </svg>*/}
                    {/*    <span class="ml-3">Zu Liste hinzufügen</span>*/}
                    {/*</button>*/}
                </div>
            </div>

            {/*Bottom*/}
            <div
                class={`${isFullscreen() ? "hidden" : ""} ${isSheetVisible() && hasShadow() ? "shadow-t-lg" : "shadow-none"} ${hasAudio() ? "pt-4" : "pt-1"} transition-shadow duration-300 z-20 relative bg-cantico-50 px-4 space-y-4 shrink-0`}
                style={{'padding-bottom': `max(env(safe-area-inset-bottom), ${hasAudio() ? "1rem" : "0.25rem"})`}}
            >

                <Show when={hasAudio()}>
                    <SegmentedAudioSlider class=""
                                          trackDurations={durations()}
                                          trackNames={trackNames()}
                                          track={track()}
                                          time={time()}
                                          onInput={seek}
                    ></SegmentedAudioSlider>
                </Show>

                {/*Buttons*/}
                <div class="w-full max-w-3xl mx-auto">
                    <div class="w-full flex flex-row justify-evenly">
                        <button class="w-14 h-14 flex justify-center items-center"
                                onClick={() => setRenderMode(renderMode() === "score" ? "text" : "score")}>
                            <svg class={`${renderMode() === "score" ? "fill-cantico-800" : "fill-gray-300"} h-12 p-2`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M499.1 6.3c8.1 6 12.9 15.6 12.9 25.7v72V368c0 44.2-43 80-96 80s-96-35.8-96-80s43-80 96-80c11.2 0 22 1.6 32 4.6V147L192 223.8V432c0 44.2-43 80-96 80s-96-35.8-96-80s43-80 96-80c11.2 0 22 1.6 32 4.6V200 128c0-14.1 9.3-26.6 22.8-30.7l320-96c9.7-2.9 20.2-1.1 28.3 5z"/></svg>
                        </button>

                        {/*TODO*/}
                        <Show when={hasAudio()}>
                            <button class="w-14 h-14 flex justify-center items-center" onClick={toggleMode}>
                                <svg class={`${isWithSinging() ? "fill-cantico-800" : "fill-gray-300"} h-12 p-2`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M96 96V256c0 53 43 96 96 96s96-43 96-96H208c-8.8 0-16-7.2-16-16s7.2-16 16-16h80V192H208c-8.8 0-16-7.2-16-16s7.2-16 16-16h80V128H208c-8.8 0-16-7.2-16-16s7.2-16 16-16h80c0-53-43-96-96-96S96 43 96 96zM320 240v16c0 70.7-57.3 128-128 128s-128-57.3-128-128V216c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 89.1 66.2 162.7 152 174.4V464H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h72 72c13.3 0 24-10.7 24-24s-10.7-24-24-24H216V430.4c85.8-11.7 152-85.3 152-174.4V216c0-13.3-10.7-24-24-24s-24 10.7-24 24v24z"/></svg>
                            </button>
                            <button
                                class={`${isPlaylistLoaded() ? "bg-cantico-800" : "bg-gray-300"} rounded-full w-14 h-14 flex justify-center items-center`}
                                onClick={playPause}
                            >
                                <Show when={isPlaying()}>
                                    <svg class="h-12 fill-white p-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z"/></svg>
                                </Show>
                                <Show when={!isPlaying()}>
                                    <div class={`${!isPlaylistLoaded() ? "animate-spin" : ""}`}>
                                        <svg class="ml-1 h-12 fill-white p-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>
                                    </div>
                                </Show>
                            </button>
                        </Show>

                        <button class="w-14 h-14 flex justify-center items-center"
                                onClick={() => setIsFavorit(!isFavorit())}>
                            <svg class={`${isFavorit() ? "" : "hidden"} ml-1 h-12 fill-rose-500 p-2`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"/></svg>
                            <svg class={`${isFavorit() ? "hidden" : ""} ml-1 h-12 fill-gray-300 p-2`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"/></svg>
                        </button>
                        <button class="w-14 h-14 flex justify-center items-center"
                                onClick={() => setIsSheetVisible(!isSheetVisible())}>
                            {/*<svg class={`${isSheetVisible() ? "rotate-180" : "rotate-0"} transition-all transform h-12 fill-cantico-800 p-2`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/></svg>*/}
                            <svg class="transition-all transform h-12 fill-cantico-800 p-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>
                            {/*<svg class="transition-all transform h-12 fill-cantico-800 p-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"> <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/></svg>*/}
                            {/* <svg class={`${sheetVisible() ? "" : ""} h-12 fill-cantico-800 p-2`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" /></svg> */}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Song