import {Directory, Encoding, Filesystem, GetUriOptions} from '@capacitor/filesystem';
import write_blob from "capacitor-blob-writer";
import {getUriIfFileExists, hashString, Mutex} from "../utils/misc";
import axios, {AxiosProgressEvent} from 'axios';

export const downloadFile = async (
    url: string,
    savePath: string,
    progressCallback?: (progressEvent: AxiosProgressEvent) => void
) => {
    try {
        console.debug(`Downloading "${url}" to "${savePath}"...`)

        const headResponse = await axios.head(url);
        const contentType = headResponse.headers['content-type'];

        let responseType: 'blob' | 'text';
        if (contentType.startsWith('application/json')) {
            responseType = 'text';
        } else {
            responseType = 'blob';
        }

        // Use axios instead of Filesystem.downloadFile(...) to have a reliable progress callbacks
        const response = await axios.get(url, {
            responseType,
            onDownloadProgress: progressCallback
        });

        let uri: string;
        if(responseType == 'blob') {
            uri = await write_blob({
                path: savePath,
                directory: Directory.Data,
                blob: response.data,
                recursive: true,
            });
        } else {
            uri = (await Filesystem.writeFile({
                path: savePath,
                directory: Directory.Data,
                data: response.data,
                recursive: true,
                encoding: Encoding.UTF8,
            })).uri
        }

        console.debug(`Downloading of "${url}" finished.`)

        return uri;
    } catch (error) {
        console.error(`Error downloading file: ${error}`);
        throw error;
    }
}

export async function getUriFromCacheOrDownload(url: string, progressCallback?: (progressEvent: AxiosProgressEvent) => void) {
    try {
        const hashedFilename = hashString(url);
        const uriOptions: GetUriOptions = {
            path: hashedFilename,
            directory: Directory.Data
        };

        let uri = await getUriIfFileExists(uriOptions);
        if (uri == null) {
            uri = await downloadFile(url, uriOptions.path, progressCallback);
        }

        return uri;
    } catch (error) {
        console.error(`Error getting URI from cache or downloading file: ${error}`);
        throw error;
    }
}

export async function readFileFromCacheOrDownload(url: string, progressCallback?: (progressEvent: AxiosProgressEvent) => void) {
    const uri = await getUriFromCacheOrDownload(url, progressCallback);
    const readResult = await Filesystem.readFile({path: uri, encoding: Encoding.UTF8});
    return readResult.data;
}

export async function downloadFiles(urls: string[], processCallback?: (progressEvent: AxiosProgressEvent) => void, numWorkers: number = 4) {
    let updateProgress: (progressEvent: AxiosProgressEvent, index: number) => void;

    if(processCallback) {
        // Get total size
        let totalBytes = 0;
        for (const url of urls) {
            const response = await axios.head(url);
            totalBytes += parseInt(response.headers['content-length'], 10);
        }

        let bytesReceived = new Array(urls.length).fill(0);
        updateProgress = (progressEvent: AxiosProgressEvent, index: number) => {
            bytesReceived[index] = progressEvent.loaded;
            const totalReceived = bytesReceived.reduce((a, b) => a + b, 0);
            const progress = totalBytes ? totalReceived / totalBytes : 0;
            processCallback({
                loaded: totalReceived,
                total: totalBytes,
                progress,
                bytes: progressEvent.loaded,
                download: true
            });
        };
    }

    const queue = urls.map(url => ({url}));
    const queueMutex = new Mutex();

    const workers = Array.from({length: numWorkers}, () => async () => {
        while (true) {
            const releaseLock = await queueMutex.lock();
            if (queue.length === 0) {
                releaseLock();
                break;
            }
            const { url } = queue.shift()!;
            releaseLock();

            if (url) {
                await getUriFromCacheOrDownload(url, (progressEvent) => updateProgress(progressEvent, urls.indexOf(url)));
            }
        }
    })

    await Promise.all(workers.map((worker) => worker()));
}

export async function downloadBook(urls: string[][], progressCallbacks: ((progressEvent: AxiosProgressEvent) => void)[]) {
    for (let i = 0; i < urls.length; i++) {
        await downloadFiles(urls[i], progressCallbacks[i]);
    }
}